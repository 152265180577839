<template>
  <div>
    <div ref="clink" style="display: none"></div>
    <!-- 最上边 -->
    <div class="center-top" v-if="isListion">
      <div class="top-name"></div>
      <!-- <div class="top-tome">
        <div class="timer">
          <div class="time-card" data-type="hours" data-max="24">
            <div class="time-card-count">{{ myHours }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="minutes" data-max="60">
            <div class="time-card-count">{{ myMinutes }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="seconds" data-max="60">
            <div class="time-card-count">{{ mySeconds }}</div>
          </div>
        </div>
      </div> -->
      <div class="top-tu">
        <audio src="../../assets/6027.mp3" ref="audioLing" loop></audio>
        <div class="top-tu-one">
          <img
            src="../../assets/images/top03.jpg"
            alt=""
            v-if="imgIcon"
            @click="callBackMessage"
          />
          <el-button
            v-if="imgIcons"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/top03.jpg" />
          </el-button>
          <!-- <button :disabled="true" v-if="imgIcons" class="el-icon-phone" style="font-size: 20px;border: 3px solid rgb(152, 140, 140);color: rgb(152, 140, 140);padding: 7px 24px;border-radius: 24px;float: inherit;margin-top: 6px;">回拨</button> -->
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/top05.jpg"
            alt=""
            @click="callBackStandby"
            v-if="imgbeiyong"
          />
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/top05.jpg"
            alt=""
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
            v-if="imgbeiyongs"
          />
        </div>
        <div class="top-tu-one">
          <img
            @click="imgIconBtn"
            v-if="imgIconBtnOne"
            src="../../assets/images/top07.jpg"
            alt=""
          />
          <el-select
            v-if="imgIconBtns"
            v-model="regions"
            @change="regionOne(regions)"
            placeholder="请选择紧急联系人"
            style="
              width: 162px;
              height: 38px;
              position: absolute;
              top: 0px;
              margin-left: 12px;
            "
          >
            <el-option
              v-for="item in region"
              :key="item.phone"
              :label="item.userName"
              :value="item.phone"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="top-rig">
        <div class="top-right">
          <img
            :class="[{ r1: shrinkPacket }, { r2: !shrinkPacket }]"
            src="../../assets/images/ling03.jpg"
            alt=""
          />
        </div>
        <!-- <div class="top-time">00:25</div> -->
        <div class="top-jie">
          <img
            src="../../assets/images/ling05.jpg"
            alt=""
            @click="listion"
            v-if="isAnswer"
          />
          <el-button v-if="isCall" @click="callIng">接听</el-button>
        </div>
        <div class="top-zhuangtai">
          <span class="my-zhuangtai">我的状态</span>
          <el-select
            v-model="valuezhuangtai"
            placeholder="请选择"
            style="width: 100px; height: 30px"
            :disabled="false"
            @change="myStatus"
          >
            <el-option
              v-for="item in optionszhuangtai"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="top-tonghua">
            <div class="timer">
              <div class="time-card" data-type="hours" data-max="24">
                <div class="time-card-count">{{ myHours2 }}</div>
              </div>
              <span class="colon">:</span>
              <div class="time-card" data-type="minutes" data-max="60">
                <div class="time-card-count">{{ myMinutes2 }}</div>
              </div>
              <span class="colon">:</span>
              <div class="time-card" data-type="seconds" data-max="60">
                <div class="time-card-count">{{ mySeconds2 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 最上边接听之后的 -->
    <div class="center-top" v-if="isListioning">
      <div class="top-name">{{ userNames }}</div>
      <div class="lisitions">接听中</div>
      <div class="top-tome">
        <div class="timer">
          <div class="time-card" data-type="hours" data-max="24">
            <div class="time-card-count">{{ myHours1 }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="minutes" data-max="60">
            <div class="time-card-count">{{ myMinutes1 }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="seconds" data-max="60">
            <div class="time-card-count">{{ mySeconds1 }}</div>
          </div>
        </div>
      </div>
      <div class="top-tu">
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jingyin_03.jpg"
            alt=""
            @click="jinyin"
            v-if="isYIN"
          />
          <img
            src="../../assets/取消静音.png"
            alt=""
            @click="jinyin"
            v-if="isJIN"
          />
        </div>
        <!-- <div class="top-tu-one">
          <img src="../../assets/images/images/zhuanjie_05.jpg" alt="" />
        </div>
        <div class="top-tu-one">
          <img src="../../assets/images/images/yiliao_07.jpg" alt="" />
        </div>
        <div class="top-tu-one">
          <img src="../../assets/images/images/jijiu_09.jpg" alt="" />
        </div>
        <div class="top-tu-one">
          <img src="../../assets/images/images/jinji_11.jpg" alt="" />
        </div> -->
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/guaduan_13.jpg"
            alt=""
            @click="offPhone"
          />
        </div>
      </div>
      <div class="top-zhuangtai1">
        <span class="my-zhuangtai">我的状态</span>
        <el-select
          v-model="valuezhuangtai"
          placeholder="请选择"
          style="width: 100px; height: 30px"
          :disabled="true"
        >
          <el-option
            v-for="item in optionszhuangtai"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="top-tonghua">
          <div class="timer">
            <div class="time-card" data-type="hours" data-max="24">
              <div class="time-card-count">{{ myHours2 }}</div>
            </div>
            <span class="colon">:</span>
            <div class="time-card" data-type="minutes" data-max="60">
              <div class="time-card-count">{{ myMinutes2 }}</div>
            </div>
            <span class="colon">:</span>
            <div class="time-card" data-type="seconds" data-max="60">
              <div class="time-card-count">{{ mySeconds2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 62px" v-if="isDiv"></div>
    <!-- tab切换 -->
    <div class="tabs">
      <div :class="[cusClass == 1 ? 'badge' : 'badges']" v-if="isLegalAid">
        {{ this.legalAid }}
      </div>
      <div
        :class="[cusClass == 1 ? 'badge1' : 'badges1']"
        v-if="isPolicyElucidation"
      >
        {{ this.policyElucidation }}
      </div>
      <div
        :class="[cusClass == 1 ? 'badge2' : 'badges2']"
        v-if="isCareSympathy"
      >
        {{ this.careSympathy }}
      </div>
      <div
        :class="[cusClass == 1 ? 'badge3' : 'badges3']"
        v-if="isShowCommission"
      >
        {{ this.Allcount }}
      </div>
      <div class="tabs-left">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="常用知识" name="first">
            <div class="search">
              <div class="search">
                <el-input
                  v-model="searchs"
                  @input="handlChange(searchs)"
                  placeholder="请输入搜索内容"
                  style="width: 180px; margin-top: 10px"
                ></el-input>
                <!--<img
                  class="imgs"
                  src="../../assets/images/query.png"
                  alt
                />-->
              </div>
            </div>
            <div class="left">
              <div class="trees">
                <el-container style="height: 675px">
                  <el-aside width="200px" class="aside-tree">
                    <el-tree
                      :data="dataList"
                      :props="defaultProps"
                      @node-click="handleNodeClick"
                      :filter-node-method="filterNode"
                      ref="tree"
                    >
                    </el-tree>
                  </el-aside>
                </el-container>
              </div>
              <div class="right">
                <p
                  v-text="dataTitle"
                  style="font-size: 18px; font-weight: bold; margin-bottom: 5px"
                ></p>
                <p v-html="dataLists"></p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="法律帮助" name="second" v-if="isLegalAid">
            <div>
              <el-table
                :data="tableDataLaw"
                style="width: 100%"
                @row-click="rowClick"
                height="740"
              >
                <el-table-column prop="workNumber" label="工单编号" width="100">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="handleTime" label="回电时间" width="100">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="190">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="lawFinsh(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="政策解读" name="third" v-if="isPolicyElucidation">
            <div>
              <el-table
                :data="tableDataPolicy"
                style="width: 100%"
                @row-click="rowClick"
                height="740"
              >
                <el-table-column prop="workNumber" label="工单编号" width="100">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="handleTime" label="回电时间" width="100">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="190">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="policyFinsh(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="关爱慰问" name="fourth" v-if="isCareSympathy">
            <div>
              <el-table
                :data="tableDataCare"
                style="width: 100%"
                @row-click="rowClick"
                height="740"
              >
                <el-table-column prop="workNumber" label="工单编号" width="100">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="handleTime" label="回电时间" width="100">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="190">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="careFinsh(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通话记录" name="five">
            <div class="tonghua">
              <el-date-picker
                v-model="timeDateShow"
                type="date"
                placeholder="选择日期"
                style="margin-right: 13px; margin-top: 3px"
                @change="timeDate(timeDateShow)"
              >
              </el-date-picker>
              <i class="el-icon-video-play"></i> 当日累计通话：{{
                this.totalTime1
              }}
            </div>
            <div>
              <el-table
                :data="tableData1"
                height="700"
                style="width: 100%"
                @row-click="rowBtn"
              >
                <el-table-column prop="name" label="通话对象" width="180">
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" width="180">
                </el-table-column>
                <el-table-column prop="bridgeDuration" label="通话时长">
                </el-table-column>
                <el-table-column prop="qiniuUrl" label="回放录音" width="250">
                  <template slot-scope="scope">
                    <audio
                      @play="handlePlay(scope.$index)"
                      name="audioOne"
                      controls
                      controlslist="nodownload"
                      :src="scope.row.qiniuUrl"
                      @input="$forceUpdate()"
                      @change="$forceUpdate()"
                      type="audio/mp3"
                    ></audio>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全部待办" name="six" v-if="isShowCommission">
            <div>
              <el-table
                :data="tableData2"
                style="width: 100%"
                height="740"
                @row-click="rowClick"
              >
                <el-table-column prop="workNumber" label="工单编号" width="90">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="handleTime" label="回电时间" width="100">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="180">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 右边 -->
      <div class="bigRigth">
        <el-tabs
          v-model="activeName1"
          type="card"
          @tab-click="handleClick1"
          class="bigRight-tab"
        >
          <el-tab-pane label="用户信息" name="first">
            <div class="information" style="width: 720px">
              <div class="inforTop">
                <div class="baseInformation">基础信息</div>
                <div class="neirong">
                  <el-form :model="formMember" :inline="true">
                    <el-col :span="10">
                      <el-form-item label="姓 名 ">
                        <el-input
                          v-model="formMember.name"
                          autocomplete="off"
                          style="width: 140px; margin-left: 11px"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="备用号码">
                        <el-input
                          v-model="formMember.NewsparePhone"
                          autocomplete="off"
                          style="width: 140px; margin-left: 15px"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="家庭ID">
                        <el-input
                          v-model="formMember.familyId"
                          autocomplete="off"
                          style="width: 140px"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-form-item
                        label="紧急联系人"
                        style="display: contents"
                      >
                        <el-select
                          :disabled="true"
                          style="width: 100px"
                          v-model="formMember.emergencyContactOne"
                          @change="
                            emergencyContact(formMember.emergencyContactOne)
                          "
                        >
                          <el-option
                            v-for="item in ursents"
                            :key="item.userName"
                            :label="item.userName"
                            :value="item.userName"
                          ></el-option>
                        </el-select>
                        <el-input
                          v-model="formMember.emergencyContactOnePhone"
                          autocomplete="off"
                          style="width: 140px"
                          :disabled="true"
                        ></el-input
                        ><br />
                        <el-select
                          :disabled="true"
                          style="width: 100px"
                          v-model="formMember.emergencyContactTwo"
                          @change="
                            emergencyContacts(formMember.emergencyContactTwo)
                          "
                        >
                          <el-option
                            v-for="item in ursentsTwo"
                            :key="item.userName"
                            :label="item.userName"
                            :value="item.userName"
                          >
                          </el-option>
                        </el-select>
                        <el-input
                          v-model="formMember.emergencyContactTwoPhone"
                          autocomplete="off"
                          style="width: 140px"
                          :disabled="true"
                        ></el-input
                        ><br />
                        <!--<el-input
                          style="width: 60px"
                          v-model="formMember.emergencyContactThree"
                          :disabled="true"
                        ></el-input>
                        <el-input
                          v-model="formMember.emergencyContactThreePhone"
                          :disabled="true"
                          autocomplete="off"
                          style="width: 140px"
                        ></el-input>-->
                      </el-form-item> </el-col
                    ><br />
                    <el-col :span="10">
                      <el-form-item label="性 别">
                        <el-select
                          v-model="formMember.sex"
                          :disabled="true"
                          placeholder="请选择"
                          style="width: 140px; margin-left: 11px"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="inforbirth">
                      <el-form-item label="生 日">
                        <el-date-picker
                          v-model="formMember.birthday"
                          type="date"
                          :disabled="true"
                          placeholder="选择日期"
                          style="width: 140px; margin-left: 11px"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="设备电话号" class="inforbirths">
                        <el-input
                          v-model="formMember.localPhone"
                          autocomplete="off"
                          style="width: 140px; margin-left: 15px"
                          :disabled="true"
                          @input="$forceUpdate()"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-form-item label="登记位置" class="dengji">
                      <el-select
                        :disabled="true"
                        v-model="formMember.province"
                        style="width: 90px"
                        @change="provinces(formMember.province)"
                      >
                        <el-option
                          v-for="item in historyProvince"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        :disabled="true"
                        v-model="city"
                        style="width: 90px"
                        @change="provincesOne(city)"
                      >
                        <el-option
                          v-for="item in historyOrderCity"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        :disabled="true"
                        v-model="county"
                        style="width: 90px"
                      >
                        <el-option
                          :disabled="true"
                          v-for="item in historycounty"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-input
                      class="inforInput"
                      type="textarea"
                      :rows="2"
                      :disabled="true"
                      placeholder="请输入内容"
                      v-model="formMember.address"
                    >
                    </el-input>
                    <el-col :span="16" class="shiji">
                      <el-form-item label="实际位置">
                        <div class="shijibox">
                          {{ formMember.address }}
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="jihuo">
                      <el-form-item label="激活日期">
                        <el-input
                          v-model="formMember.joinDate"
                          autocomplete="off"
                          :disabled="true"
                          style="width: 110px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="daoqi">
                      <el-form-item label="到期日期">
                        <el-input
                          v-model="formMember.leaveDate"
                          :disabled="true"
                          autocomplete="off"
                          style="width: 110px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-form>
                </div>
              </div>
              <div class="big-center">
                <div class="leftcenter">
                  <span class="span1">生活状况</span>
                  <table>
                    <tr>
                      <td>居住情况</td>
                      <td>
                        <el-select
                          :disabled="true"
                          v-model="valueLive"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in live"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>智力状态</td>
                      <td>
                        <el-select
                          :disabled="true"
                          v-model="valuezhiLi"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in zhiLi"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>性格特征</td>
                      <td>
                        <el-select
                          :disabled="true"
                          v-model="valuexingge"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in xingge"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>身体状态</td>
                      <td>
                        <div class="tdspan">
                          <span
                            >语言：
                            <el-select
                              :disabled="true"
                              v-model="valueyuyan"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in yuyan"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span
                            >听力：
                            <el-select
                              :disabled="true"
                              v-model="valuetingli"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in tingli"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span
                            >视力：
                            <el-select
                              :disabled="true"
                              v-model="valueshili"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in shili"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span>
                            行动：
                            <el-select
                              :disabled="true"
                              v-model="valuexingdong"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in xingdong"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>慢性病史</td>
                      <td>
                        <el-select
                          :disabled="true"
                          v-model="valuemanxing"
                          placeholder="请选择"
                          style="width: 150px"
                        >
                          <el-option
                            v-for="item in manxing"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="infor-img">
                        <img src="../../assets/images/add.png" alt="" />
                      </td>
                    </tr> -->
                  </table>
                </div>
                <div class="rightcenter">
                  <span class="span1">健康数据</span>
                  <table>
                    <tr>
                      <td>血压</td>
                      <td>{{ forBody.bloodPressure }}</td>
                    </tr>
                    <tr>
                      <td>血糖</td>
                      <td>
                        <div class="tdspan">
                          <span>{{ forBody.bloodSugar }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>血氧</td>
                      <td>{{ forBody.oxygen }}</td>
                    </tr>
                    <tr>
                      <td>心率</td>
                      <td>{{ forBody.heartRate }}</td>
                    </tr>
                    <tr>
                      <td>体温</td>
                      <td>{{ forBody.temperature }}</td>
                    </tr>
                    <tr>
                      <td>睡眠</td>
                      <td>{{ forBody.sleep }}</td>
                    </tr>
                    <tr>
                      <td>用药安排</td>
                      <!-- <td>
                        <div class="tdspan">
                          <span
                            >{{ morningbefor.medicationDate }}
                            {{ morningbefor.drugCode }}
                            {{ morningbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ morningafter.medicationDate }}
                            {{ morningafter.drugCode }}
                            {{ morningafter.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ lunchbefor.medicationDate }}
                            {{ lunchbefor.drugCode }}
                            {{ lunchbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ lunchafter.medicationDate }}
                            {{ lunchafter.drugCode }}
                            {{ lunchafter.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ eveningbefor.medicationDate }}
                            {{ eveningbefor.drugCode }}
                            {{ eveningbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span>晚餐后 xxxx 1颗</span>
                        </div>
                      </td> -->
                    </tr>
                  </table>
                  <!-- <span class="buttons baocun">保存</span> -->
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="工单管理" name="second">
            <div class="history">
              <div class="historydan">历史工单</div>
              <ul class="history-ul">
                <li v-for="(item, index) in historyList" :key="index">
                  <div class="suggest">{{ item.type }}</div>
                  <span class="times">{{ item.createDate }}</span
                  ><span class="person">客服</span
                  ><span class="times">{{ item.userName }}</span>
                  <span class="person-id">IMEI</span
                  ><span class="times">{{ item.equipmentNo }}</span>
                  <span class="person-id">ID</span
                  ><span class="times">{{ item.workNumber }}</span>
                  <div class="suggest1">{{ item.typeDetail }}</div>
                  <div class="content">{{ item.describe }}</div>
                </li>
              </ul>
            </div>
            <div class="create-order">
              <div class="historydan1">创建工单</div>
              <span>
                <el-select
                  v-model="valueOrder"
                  placeholder="请选择"
                  style="width: 120px"
                  @change="changexiaLa"
                >
                  <el-option
                    v-for="item in historyOrder"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </span>
              <div class="infor-content" v-if="isXian">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz">
                      <el-checkbox
                        v-for="item in checkList"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span
                      ><el-checkbox
                        label="需要回电"
                        @change="callChange"
                        v-model="callback1"
                        true-label="1"
                        false-label="0"
                      ></el-checkbox
                    ></span>
                    <span>
                      <el-select
                        v-if="valueType"
                        v-model="valueOrdertime"
                        style="width: 80px"
                        @change="handelChange"
                      >
                        <el-option
                          v-for="item in historyOrdertime"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span>
                      <el-select
                        v-if="valueType"
                        v-model="valueOrderputong"
                        style="width: 80px"
                        @change="handelChangeHistory"
                      >
                        <el-option
                          v-for="item in historyOrderputong"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <div>
                      <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>{{ usersName }}</span>
                      </span>
                      <el-button type="primary" @click="finish" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian1">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName1 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz1">
                      <el-checkbox
                        v-for="item in checkList1"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange1($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName2 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz2">
                      <el-checkbox
                        v-for="item in checkList2"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange2($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span>回电时间</span>
                    <el-select
                      v-model="handleTime"
                      style="width: 120px"
                      @change="handelChange1"
                    >
                      <el-option
                        v-for="item in historyOrdertimes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish1" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian2">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName3 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz3">
                      <el-checkbox
                        v-for="item in checkList3"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange3($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName4 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz4">
                      <el-checkbox
                        v-for="item in checkList4"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange4($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span>回电时间</span>
                    <el-select
                      v-model="handleTime"
                      style="width: 120px"
                      @change="handelChange2"
                    >
                      <el-option
                        v-for="item in historyOrdertimes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish2" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian3">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName5 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz5">
                      <el-checkbox
                        v-for="item in checkList5"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange5($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName6 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz6">
                      <el-checkbox
                        v-for="item in checkList6"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange6($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span>回电时间</span>
                    <el-select
                      v-model="handleTime"
                      style="width: 120px"
                      @change="handelChange3"
                    >
                      <el-option
                        v-for="item in historyOrdertimes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish3" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian4">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName7 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz7">
                      <el-checkbox
                        v-for="item in checkList7"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange7($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName8 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group v-model="zz8">
                      <el-checkbox
                        v-for="item in checkList8"
                        :key="item.id"
                        :label="item.id"
                        @change="checkboxChange8($event, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span
                      ><el-checkbox
                        label="需要回电"
                        @change="callChanges"
                        v-model="callback2"
                        true-label="1"
                        false-label="0"
                      ></el-checkbox
                    ></span>
                    <span>
                      <el-select
                        v-if="valueTypes"
                        v-model="valueOrdertime1"
                        style="width: 80px"
                        @change="handelChange4"
                      >
                        <el-option
                          v-for="item in historyOrdertime1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span>
                      <el-select
                        v-if="valueTypes"
                        v-model="valueOrderputong1"
                        style="width: 80px"
                        @change="handelChangeHistory1"
                      >
                        <el-option
                          v-for="item in historyOrderputong1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <div>
                      <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span>
                      <el-button type="primary" @click="finish4" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="远程协助" name="third">远程协助</el-tab-pane> -->
          <el-tab-pane label="生活服务" name="fourth">生活服务</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <audio
      ref="audioLing"
      loop
      src="../../assets/6027.mp3"
      hidden
      muted
    ></audio>
    <!-- 点击关联人员的一个弹框 -->
    <el-dialog :visible.sync="dialogVisible" width="13%">
      <el-table
        :data="tableDataPerson"
        style="width: 100%"
        @cell-click="cellClick"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="name" label="关联客服" width="180">
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 点击待我办理中的完成时的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleFinish"
      width="30%"
      :before-close="handleCloseFinish"
    >
      <span>确定要完成该工单吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFinish = false">取 消</el-button>
        <el-button type="primary" @click="quitFinish">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { mapState } from "vuex";
import { api } from "../../api1/config";
import { CodeToText } from "element-china-area-data";
import axios from "axios";
export default {
  inject: ["reload"],
  computed: {
    ...mapState(["mes"]),
  },
  data() {
    return {
      cusClass: "",
      pNumbers: "",
      isCall: false,
      isAnswer: false, //是否显示最上边的按钮
      //铃响
      shrinkPacket: "",
      checkListData: [],
      zz: [],
      danName: "",
      checkListData1: [],
      zz1: [],
      danName1: "",
      zz2: [],
      danName2: "",
      checkListData2: [],
      zz3: [],
      danName3: "",
      zz4: [],
      danName4: "",
      checkListData3: [],
      zz5: [],
      danName5: "",
      zz6: [],
      danName6: "",
      checkListData4: [],
      zz7: [],
      danName7: "",
      zz8: [],
      danName8: "",
      isYIN: true,
      isJIN: false,
      isHelp: false,
      usersName: "关联相关人员",
      endStatus: "0",
      dialogVisibleFinish: false, //待我办理的完成
      audioElement: [], // 创建一个数组
      userNames: "",
      allPhone: "",
      valueType: false,
      valueTypes: false,
      timeDateShow: "",
      imgIconBtnOne: true,
      imgIconBtns: false,
      imgIcon: false,
      imgIcons: true,
      imgbeiyong: false,
      imgbeiyongs: true,
      //紧急联系人
      regions: "",
      region: [],
      isShowCommission: true, //全部待办是否显示
      isLegalAid: true, //法律帮助是否显示
      isPolicyElucidation: true, //政策解读是否显示
      isCareSympathy: true, //关爱慰问是否显示
      Allcount: "", //全部待办的数量统计
      dialogVisible: false,
      dialogVisible1: false,
      isListion: true, //是否接听
      isListioning: false, //正在接听
      isXian: true,
      isXian1: false,
      isXian2: false,
      isXian3: false,
      isXian4: false,
      //最上边的客服状态
      valuezhuangtai: "忙碌",
      optionszhuangtai: [
        {
          value: "0",
          label: "空闲",
        },
        {
          value: "1",
          label: "忙碌",
        },
      ],
      labelPosition: "right",
      activeName: "second",
      activeName1: "first",
      searchs: "",
      tabPosition: "left",
      //性别下拉
      options: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      value: "",
      value1: "", //日期下拉
      province: [], //登记位置
      city: "", //登记位置
      county: "", //登记位置
      historyProvince: [],
      historyOrderCity: [],
      historycounty: [],
      value2: [], //登记位置
      value3: [], //登记位置
      value4: [], //登记位置
      options2: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      //生活状况 居住
      live: [
        {
          value: "1",
          label: "独居（无保姆）",
        },
        {
          value: "2",
          label: "与子女住",
        },
      ],
      valueLive: "",
      zhiLi: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuezhiLi: "",
      xingge: [
        {
          value: "1",
          label: "外向/热情",
        },
        {
          value: "2",
          label: "内向",
        },
      ],
      valuexingge: "",
      yuyan: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valueyuyan: "",
      tingli: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuetingli: "",
      shili: [
        {
          value: "1",
          label: "弱",
        },
        {
          value: "2",
          label: "强",
        },
      ],
      valueshili: "",
      xingdong: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuexingdong: "",
      manxing: [
        {
          value: "1",
          label: "高血压",
        },
        {
          value: "2",
          label: "糖尿病",
        },
        {
          value: "3",
          label: "高脂血综合征",
        },
      ],
      valuemanxing: "",
      //创建工单中的 信息记录下拉
      type: "",
      checkList: [],
      checkList1: [],
      checkList2: [],
      checkList3: [],
      checkList4: [],
      checkList5: [],
      checkList6: [],
      checkList7: [],
      checkList8: [],
      handleTime: "",
      priority: "",
      historyOrder: [],
      valueOrder: "",
      radio: "1",
      textarea: "",
      historyOrdertime: [
        {
          value: "1",
          label: "1小时",
        },
        {
          value: "2",
          label: "2小时",
        },
        {
          value: "3",
          label: "3小时",
        },
        {
          value: "6",
          label: "6小时",
        },
        {
          value: "24",
          label: "24小时",
        },
        {
          value: "48",
          label: "48小时",
        },
        {
          value: "72",
          label: "72小时",
        },
        {
          value: "0",
          label: "不限制",
        },
      ],
      historyOrdertime1: [
        {
          value: "1",
          label: "1小时",
        },
        {
          value: "2",
          label: "2小时",
        },
        {
          value: "3",
          label: "3小时",
        },
        {
          value: "6",
          label: "6小时",
        },
        {
          value: "24",
          label: "24小时",
        },
        {
          value: "48",
          label: "48小时",
        },
        {
          value: "72",
          label: "72小时",
        },
        {
          value: "0",
          label: "不限制",
        },
      ],
      historyOrdertimes: [
        {
          value: "0",
          label: "不限时间",
        },
        {
          value: "上午（09:00-11:00）",
          label: "上午（09:00-11:00）",
        },
        {
          value: "下午（13:00-17:00）",
          label: "下午（13:00-17:00）",
        },
      ],
      valueOrdertime: "",
      valueOrdertime1: "",
      // valueOrdertimes: "",
      historyOrderputong: [
        {
          value: "1",
          label: "紧急",
        },
        {
          value: "2",
          label: "高",
        },
        {
          value: "3",
          label: "普通",
        },
        {
          value: "4",
          label: "低",
        },
      ],
      historyOrderputong1: [
        {
          value: "1",
          label: "紧急",
        },
        {
          value: "2",
          label: "高",
        },
        {
          value: "3",
          label: "普通",
        },
        {
          value: "4",
          label: "低",
        },
      ],
      valueOrderputong: "",
      valueOrderputong1: "",
      //表格数据
      tableDataLaw: [],
      tableDataPolicy: [],
      tableDataCare: [],
      tableData1: [],
      totalTime1: "", //通话记录总时长
      tableData2: [],
      dataList: [],
      dataLists: "",
      dataTitle: "",
      defaultProps: {
        children: "childs",
        label: "title",
      },
      formMember: {}, //信息查询的基础信息
      form: {},
      forBody: {}, //健康数据
      morningbefor: {}, //早餐前
      morningafter: {}, //早餐后
      lunchbefor: {}, //午餐前
      lunchafter: {}, //午餐后
      eveningbefor: {}, //晚餐前
      sparePhoneTwo: "",
      //历史工单
      historyList: [],
      //法律帮助
      legalAid: "",
      //政策解读
      policyElucidation: "",
      //关爱慰问
      careSympathy: "",
      //分配来电的信息
      dataTime: "",
      tel: "18135128546",
      phone: "",
      equipmentId: null,
      id: "",
      mainUniqueId: "",
      userId: "", //关联客服id
      callBack: "",
      customerId: "", //通话用户id
      //来电
      currentTime: 0,
      timeObj: null, // 时间对象,下方会用到
      myHours: "00", // 我定义来接收计算出来的 小时 的
      myMinutes: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds: "00", // 我定义来接收计算出来的 秒钟 的
      currentTime1: 0,
      timeObj1: null, // 时间对象,下方会用到
      myHours1: "00", // 我定义来接收计算出来的 小时 的
      myMinutes1: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds1: "00", // 我定义来接收计算出来的 秒钟 的
      currentTime2: 0,
      timeObj2: null, // 时间对象,下方会用到
      myHours2: "00", // 我定义来接收计算出来的 小时 的
      myMinutes2: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds2: "00", // 我定义来接收计算出来的 秒钟 的
      cno: "",
      //改派
      quitedId: "",
      //关联人员
      tableDataPerson: [{ name: "王小虎" }],
      //是否需要回电
      callback1: 0,
      callback2: 0,
      userdd: "",
      //计时器
      timer1: null,
      timer2: null,
      timer4: null,
      timer5: null,
      phones: null,
      localPhone: "",
      customerIds: null,
      equipmentIds: null,
      phoneList: null,
      vIdType: "",
      color: "pink",
      userColor: "",
      muteCount: 0,
      isDiv: false,
      //回拨的参数
      backUserId: "",
      quFinish: {}, //完成的参数
      ursents: [],
      ursentsTwo: [],
      familyIds: null,
      emergencyOnes: null,
      emergencyTwos: null,
      Sphone: "",
      loginToken: "",
      strPhone: null,
      strUser: null,
      strequipmentId: null,
      strMessage: "",
      strendReason: "",
      mscode: null,
      mainUniqueIds: "",
      UniqueIds: "",
      strcode: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 1200000, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      calltypes: "",
      strcodes: null,
    };
  },
  created() {
    //获取cno
    let cno = JSON.parse(localStorage.getItem("cno"));
    this.cno = cno;
    //判断是否为客服
    let cus = JSON.parse(localStorage.getItem("cusSer"));
    this.cusClass = cus;
    if (cus == "1") {
      this.initWebSocket();
      //客服状态
      this.myStatus(1);
      console.log("是客服");
    } else {
      console.log("不是客服");
      //不是客服
      this.isListion = false;
      this.isListioning = false;
      this.isDiv = true;
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      // clearInterval(this.timer3);
      clearInterval(this.timer4);
      clearInterval(this.timers);
      // clearInterval(this.timer5);
      clearInterval(this.timer6);
    }
    //获取关联客服id
    let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
    this.userId = loginUserId.id;
    this.loginToken = loginUserId.token;
    console.log(this.userId, this.loginToken, "用户id和token");

    //tab的显示与否
    // if (loginUserId.post == "2") {
    //   //客服组长
    //   this.isShowCommission = true;
    //   this.isLegalAid = true;
    //   this.isPolicyElucidation = true;
    //   this.isCareSympathy = true;
    // } else if (loginUserId.post == "3") {
    //   //法律帮助
    //   this.isLegalAid = true;
    //   this.isShowCommission = false;
    //   this.isPolicyElucidation = false;
    //   this.isCareSympathy = false;
    // } else if (loginUserId.post == "4") {
    //   //政策解读
    //   this.isLegalAid = false;
    //   this.isShowCommission = false;
    //   this.isPolicyElucidation = true;
    //   this.isCareSympathy = false;
    // } else if (loginUserId.post == "5") {
    //   //关爱慰问
    //   this.isLegalAid = false;
    //   this.isShowCommission = false;
    //   this.isPolicyElucidation = false;
    //   this.isCareSympathy = true;
    // }
    //查询机构下客服类型所有客服接口
    api.getallTypeUser({ serviceType: 2 }).then((res) => {
      // console.log('查询机构下客服类型所有客服接口:',res.data);
      if (res.data.code == 200) {
        this.tableDataPerson = res.data.result;
      } else {
        this.$message.error(res.data.msg);
      }
    });
    //获取常用知识
    apiShout.getKnowledge().then((res) => {
      console.log("常用知识：", res.data);
      if (res.data.code === 200) {
        this.dataList = res.data.result;
        this.dataLists = res.data.result;
        if (this.dataLists.length > 0) {
          for (var i = 0; i < this.dataLists.length; i++) {
            for (var j = 0; j < this.dataLists[i].childs.length; j++) {
              this.dataLists = this.dataLists[i].childs[0].text;
              this.dataTitle = this.dataLists[i].childs[0].title;
            }
          }
        } else {
          this.dataLists = "";
        }
      } else {
        this.$message.error(res.data.msg);
      }
    });
    this.offenKnow();
    //获取省份城市接口
    api.AreaCity({ parentId: 0 }).then((res) => {
      // this.historyOrderCity = res.data.result;
      this.historyProvince = res.data.result; //渲染省
    });
    //全部待办
    this.backlog();
    //获取登录人的id
    let aa = JSON.parse(localStorage.getItem("loginuser"));
    let uId = aa.id;
    // console.log('===========1:',uId);
    this.userdd = uId;
    //查询法律帮助工单
    this.law();
    //查询关爱慰问工单
    this.care();
    //查询政策解读工单
    this.policy();
    //创建工单
    this.gongDan();
    ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
      ClinkAgent.registerListener(
        ClinkAgent.EventType.KICKOUT,
        function (token) {
          console.log("被踢下线=====================：", token);
          if (token.cno != null || token.cno != "") {
            //退出天润的登录
            ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
              // 传入参数对象
              var params = {};
              params.logoutMode = 1;
              params.removeBinding = 0;
              ClinkAgent.logout(params);
            });
            let that = this;
            clearInterval(that.timer);
            clearInterval(that.timer1);
            clearInterval(that.timer2);
            clearInterval(that.timer3);
            clearInterval(that.timer4);
            // clearInterval(that.timer5);
            clearInterval(that.timer6);
            console.log("判断了-------------");
          }
        }
      ); // 被踢下线
    });
    this.tokenM = setInterval(this.tokenMethods, 60000);
  },

  mounted() {
    this.audioElement = document.getElementsByName("audioOne"); // 获取页面上所有的video对象
    console.log(this.audioElement, "输出音频");
    let domContainer = this.$refs.clink;
    new ClinkToolbar(this.options, domContainer, this.callback);
    this.dateTime();
    //console.log(ClinkToolbar);
  },
  watch: {
    searchs(val) {
      this.$refs.tree.filter(val);
      console.log(val, "控件输出");
    },
  },
  methods: {
    callIng() {
      // var objs = JSON.stringify({
      //   cno: this.cno,
      //   msgcode: 210,
      //   token: this.loginToken,
      //   phone: this.textPhone || "",
      //   userid: 0,
      //   equipmentid: null,
      //   calltype: "1",
      // });
      // this.websocketsend(objs);
      ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
        ClinkAgent.sipLink();
      });
    },
    //点击接听事件
    listion() {
      debugger;
      if (this.cnoStatus != "0" || this.cnoStatus != 0) {
        return false;
      } else {
        console.log(this.strcodes, "查询this.strcodes");
        this.activeName1 = "first";
        //清空通话记录ID
        this.UniqueIds = "";
        // //获取电话通话记录ID
        // this.timer6 = setInterval(this.phoneID, 1000);
        //请求挂机接口
        // this.timer5 = setInterval(this.endReason, 1000);
        //铃不动不响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        //记时结束
        clearInterval(this.timer1);
        this.currentTime = 0;
        this.Personstatus = "呼叫中";
        this.PersonstatusIng = true;
        // //不再请求后台是否分配来电
        // clearInterval(this.timer3);

        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        //客服状态置忙下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        clearInterval(this.timers);

        //记时通话时间
        this.testTimer1();
        // //将客服状态置忙
        // apiShout.getBusy({ cno: this.cno }).then((aa) => {
        //   console.log("客服置忙：", aa.data);
        // });
        this.eventList = false;

        if (this.strcodes === 112) {
          console.log(this.strcodes, "走的直接接听");
          var objss = JSON.stringify({
            cno: this.cno,
            msgcode: 204,
            token: this.loginToken,
            phone: this.pNumbers || "",
          });
          this.websocketsend(objss);
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // ClinkAgent.registerCallback(ClinkAgent.ResponseType.SIP_LINK, function(event) {
            //   console.log('软电话接听-----------0：',event);
            // }); // 软电话接听
            ClinkAgent.sipLink();
          });
        }
        //切换上边
        setTimeout(() => {
          this.isListioning = true;
        }, 1500);
        this.isListion = false;
        let kk = JSON.parse(localStorage.getItem("userId"));
        if (kk == null || kk == "") {
          console.log(
            "热线走这里11",
            this.strPhone,
            this.textPhone,
            this.tel,
            this.formMember
          );
          this.formMember.localPhone = this.strPhone;
          this.userId = "";
          this.customerIds = "" || null;
          console.log(this.formMember.localPhone, "热线走这里1");
          localStorage.removeItem("userIds");
          console.log(this.formMember.name, "查询用户名称是否为空！");
          let arr = Object.keys(this.formMember);
          console.log(arr, "chaxunarr");
          if (!arr.length) {
            console.log("走到这里了");
            Object.keys(this.formMember).forEach((item) => {
              this.$delete(this.formMember, item);
            });
            Object.keys(this.formMemberInfo).forEach((item) => {
              this.$delete(this.formMemberInfo, item);
            });
            Object.keys(this.formMembers).forEach((item) => {
              this.$delete(this.formMembers, item);
            });
          }
          this.historyList = undefined;
          this.formMember.sparePhone = "";
          this.formMember.city = "";
          this.formMember.county = "";
        } else {
          console.log("热线走这里2");
          this.userId = kk;
          this.customerIds = kk;
          localStorage.setItem("userIds", JSON.stringify(kk));
          // //查询用户历史工单
          apiShout.getUserHistory({ customerId: kk }).then((dd) => {
            console.log("热线走这里22");
            console.log("用户历史工单:", dd.data);
            if (dd.data.code === 200) {
              //对于日期的处理
              for (let i = 0; i < dd.data.result.length; i++) {
                if (dd.data.result[i].createDate == null) {
                  dd.data.result[i].createDate = "";
                } else {
                  let a = dd.data.result[i].createDate;
                  // let mm = dd.data.result[i].typeDetail.slice(1, -1);
                  let ds = new Date(a);
                  let y = ds.getFullYear();
                  let m = ds.getMonth() + 1;
                  m = m < 10 ? "0" + m : m;
                  let d = ds.getDate();
                  d = d < 10 ? "0" + d : d;
                  let h = ds.getHours();
                  let minute = ds.getMinutes();
                  minute = minute < 10 ? "0" + minute : minute;
                  let second = ds.getSeconds();
                  second = second < 10 ? "0" + second : second;
                  dd.data.result[i].createDate =
                    y +
                    "-" +
                    m +
                    "-" +
                    d +
                    " " +
                    h +
                    ":" +
                    minute +
                    ":" +
                    second;
                  // dd.data.result[i].typeDetail = mm;
                }
              }
              this.historyList = dd.data.result;
              this.eventList = false;
              console.log(this.eventList, "接听的false");
            } else {
              this.$message.error(dd.data.msg);
            }
          });
          //查询用户信息
          apiShout.getMemberInfoByIds({ id: kk }).then((dat) => {
            console.log("用户信息：", dat.data);
            if (dat.data.code === 200) {
              this.regionTong = [];
              this.region = [];
              //对于生日的处理
              let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday = y + "-" + m + "-" + d;
              //对于激活日期的处理
              let b = dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
              //对于到期日期的处理
              let c = dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
              this.formMember = dat.data.result.member;
              this.xinLog = this.formMember.lon;
              this.xinLat = this.formMember.lat;
              this.xinLoc = this.formMember.location;
              this.formMembers = dat.data.result.member;
              this.familyIds = dat.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              this.ursents = [];
              this.ursentsTwo = [];
              this.region = [];
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res.data.result, "接听时的紧急联系人");

                  // console.log(this.ursents, "紧急联系人输出有多少");
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    this.region.push(item);
                    console.log(item, "item紧急联系人有几个");
                    // console.log(this.ursents, "紧急联系人输出有多少");
                  });
                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[i];
                    if (this.userPhone.id === this.emergencyOnes) {
                      this.formMember.emergencyContactOnePhone =
                        this.userPhone.phone;
                      this.formMember.emergencyContactOne =
                        this.userPhone.userName;
                      let str = this.formMember.emergencyContactOnePhone;
                      this.formMember.emergencyContactOnePhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                      let arrText = {};
                      arrText.userName = this.userPhone.userName;
                      arrText.phone = this.userPhone.phone;
                      this.regionTong.push(arrText);
                    }
                  }
                  for (let i = 0; i < this.ursentsTwo.length; i++) {
                    this.userPhones = this.ursentsTwo[i];
                    if (this.userPhones.id === this.emergencyTwos) {
                      this.formMember.emergencyContactTwoPhone =
                        this.userPhones.phone;
                      this.formMember.emergencyContactTwo =
                        this.userPhones.userName;
                      let str = this.formMember.emergencyContactTwoPhone;
                      this.formMember.emergencyContactTwoPhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                      let arrText = {};
                      arrText.userName = this.userPhones.userName;
                      arrText.phone = this.userPhones.phone;
                      this.regionTong.push(arrText);
                    }
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
              this.Sphone = dat.data.result.member.sparePhone;
              console.log(this.Sphone, "备用手机号查看");
              if (this.Sphone != null) {
                this.StandbyIcon = true;
                this.StandbyIcons = false;
              } else {
                this.StandbyIcon = false;
                this.StandbyIcons = true;
              }
              let str = this.formMember.sparePhone;
              if (str !== null) {
                this.formMember.NewsparePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
              }
              this.formMemberInfo = dat.data.result.memberInfo;
              this.cityName = dat.data.result.member.city;
              this.countyName = dat.data.result.member.county;
              this.cityId = dat.data.result.member.city;
              this.countyId = dat.data.result.member.county;
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              console.log(
                this.emergencyOnes,
                this.emergencyTwos,
                "查询紧急联系人的匹配值"
              );
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              if (this.infoFlag == true) {
                this.formMember.city = dat.data.result.member.city;
                this.formMember.county = dat.data.result.member.county;
              }
              this.formMember.birthday = dat.data.result.member.birthday;
              this.elderlyId = dat.data.result.member.id;
              this.formMemberInfo.intellect =
                dat.data.result.memberInfo.intellect;
              this.formMemberInfo.character =
                dat.data.result.memberInfo.character;
              this.formMemberInfo.language =
                dat.data.result.memberInfo.language;
              this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
              this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
              this.formMemberInfo.action = dat.data.result.memberInfo.action;
              this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
            } else {
              this.$message.error(dat.data.msg);
            }
            this.imgIconBtns = false;
            this.imgIconBtnOne = true;
            console.log(this.familyIds, "家庭id");
          });
        }
        console.log(this.eventList, "接听的false");
        if (this.userId == null) {
          this.formMember = {};
          this.formMemberInfo = {};
          this.historyList = [];
          this.formMembers.createDate = "";
          this.formMembers.updateDate = "";
        }
      }
      // return false;
      //请求挂机接口
      // this.timer5 = setInterval(this.endReason, 1000);
    },
    //有来电时铃抖动
    startRotate() {
      // clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.shrinkPacket = !this.shrinkPacket;
      }, 150);
    },
    //来电时的记时
    testTimer() {
      this.timer1 = setInterval(() => {
        this.timeFunction();
      }, 1000);
    },
    timeFunction() {
      // 开始执行倒计时
      this.timeObj = {
        // 时间对象
        seconds: Math.floor(this.currentTime % 60),
        minutes: Math.floor(this.currentTime / 60) % 60,
        hours: Math.floor(this.currentTime / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours =
        this.timeObj.hours < 10 ? "0" + this.timeObj.hours : this.timeObj.hours;
      this.myMinutes =
        this.timeObj.minutes < 10
          ? "0" + this.timeObj.minutes
          : this.timeObj.minutes;
      this.mySeconds =
        this.timeObj.seconds < 10
          ? "0" + this.timeObj.seconds
          : this.timeObj.seconds;
      this.currentTime++;
    },
    shout() {
      if (this.isJIN == true) {
        this.muteCount = 0;
        this.isYIN = true;
        this.isJIN = false;
      }

      if (this.mscode == 111 || this.mscode == "111") {
        //铃响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.isListion = true;
        this.isListioning = false;
        this.cnoStatus = 1;
        if (this.isAnswer == true) {
          this.isAnswer = false;
        }
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        console.log("取消铃声，走到这一步");
        return false;
      } else {
        if (this.cnoStatus != "0" || this.cnoStatus != 0) {
          return false;
        } else {
          if (this.strPhone == null) {
            // clearInterval(this.timer5);
            clearInterval(this.timer);
            clearInterval(this.timer2);
            this.currentTime1 = 0;
            clearInterval(this.timer1);
            this.currentTime = 0;
            //隐藏接听按钮
            this.isAnswer = false;
          } else if (this.strPhone != null) {
            this.activeName1 = "first";
            this.textPhone = this.strPhone;
            clearInterval(this.timer);
            clearInterval(this.timer1);
            this.currentTime = 0;
            this.currentTime1 = 0;
            //显示接听按钮
            this.isAnswer = true;
            //铃响
            this.startRotate();
            this.$refs.audioLing.play();
            //开始计时器
            this.testTimer();
            this.tel = this.strPhone;
            localStorage.setItem("phoneNumber", JSON.stringify(this.strPhone));
            localStorage.setItem(
              "strequipmentIds",
              JSON.stringify(this.strequipmentId)
            );
            this.userId = this.strUser;
            //将信息保存到本地中
            let obj = {
              tel: this.strPhone,
              equipmentId: this.strequipmentId,
              // dataTime: res.data.result.dataTime,
              userId: this.strUser,
            };
            localStorage.setItem("createOrder", JSON.stringify(obj));
            //保存到本地
            localStorage.setItem("userId", JSON.stringify(this.strUser));
            let namePhone = JSON.parse(localStorage.getItem("userId"));
            this.customerIds = namePhone;
            console.log(this.customerIds, "来电的时候出现的id");
            let strequipmentIdN = JSON.parse(
              localStorage.getItem("strequipmentIds")
            );
            this.equipmentId = strequipmentIdN;
            if (this.strPhone != null) {
              this.imgIcon = true;
              this.imgIcons = false;
            } else {
              this.imgIcons = true;
              this.imgIcon = false;
            }
            if (this.strPhone != null) {
              this.StandbyIcon = true;
              this.StandbyIcons = false;
            } else {
              this.StandbyIcon = false;
              this.StandbyIcons = true;
            }
          }

          //存本地
          // localStorage.setItem("calPhones",JSON.stringify(this.textPhone));
          if (this.textPhone != null) {
            const audioElement = this.audioElement;
            if (audioElement && audioElement.length > 0) {
              for (let i = 0; i < audioElement.length; i++) {
                this.audioElement[i].pause();
              }
            }
          }
          // });
        }
      }
    },
    gongDan() {
      apiShout.getworkType().then((res) => {
        console.log("创建工单的接口：", res.data);
        if (res.data.code == 200) {
          this.historyOrder = res.data.result;
          this.checkList = res.data.result[0].children[0].children;
          this.checkList1 = res.data.result[1].children[0].children;
          this.checkList2 = res.data.result[1].children[1].children;
          this.checkList3 = res.data.result[2].children[0].children;
          this.checkList4 = res.data.result[2].children[1].children;
          this.checkList5 = res.data.result[3].children[0].children;
          this.checkList6 = res.data.result[3].children[1].children;
          this.checkList7 = res.data.result[4].children[0].children;
          this.checkList8 = res.data.result[4].children[1].children;
          console.log("工单的第一个：", this.checkList1, this.checkList2);
          this.danName = res.data.result[0].children[0].name;
          this.danName1 = res.data.result[1].children[0].name;
          this.danName2 = res.data.result[1].children[1].name;
          this.danName3 = res.data.result[2].children[0].name;
          this.danName4 = res.data.result[2].children[1].name;
          this.danName5 = res.data.result[3].children[0].name;
          this.danName6 = res.data.result[3].children[1].name;
          this.danName7 = res.data.result[4].children[0].name;
          this.danName8 = res.data.result[4].children[1].name;
          console.log("--", this.danName1, this.danName2);
        }
      });
    },
    tokenMethods() {
      //获取关联客服id
      let loginUserId = JSON.parse(localStorage.getItem("token"));
      this.loginToken = loginUserId;
      api.checkTokens({ token: this.loginToken }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
        } else {
          this.$message.error(res.data.msg);
          this.$router.push("/");
        }
      });
    },
    close() {
      this.websock.close(); //关闭websocket
      this.websock.onclose = function (e) {
        console.log(e); //监听关闭事件
        console.log("关闭");
        this.$router.push("/");
        // this.initWebSocket();
      };
    },
    initWebSocket() {
      var ws = "wss://dev.careld.cn/socket";
      // var ws = "wss://erp.careld.cn/socket";
      this.websock = new WebSocket(ws);
      // 判断是否websocket是否已经打开，如果已经打开则直接发送数据  readyState
      if (this.websock.readyState !== WebSocket.OPEN) {
        this.websock.onopen = this.websocketonopen;
      } else {
        var objn = JSON.stringify({
          cno: this.cno,
          msgcode: "201",
          source: "200116212001230",
          token: this.loginToken,
        });
        this.websocketsend(objn);
      }
      // this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onclose = this.websocketclose;
    },
    reconnect() {
      //重新连接
      debugger;
      console.log("重新连接000");
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
        console.log("重新连接111");
      }, 10000);
    },
    // resets() {
    //   //重置心跳
    //   var that = this;
    //   //清除时间
    //   clearTimeout(that.timeoutObj);
    //   clearTimeout(that.serverTimeoutObj);
    //   //重启心跳
    //   that.start();
    // },
    // start() {
    //   //开启心跳
    //   console.log("开启心跳");
    //   var self = this;
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   self.timeoutObj = setTimeout(function () {
    //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //     if (this.websock.readyState == 1) {
    //       //如果连接正常
    //       var timeout = JSON.stringify({
    //         cno: this.cno,
    //         msgcode: 200,
    //         source: "",
    //         token: this.loginToken,
    //       });
    //       this.websocketsend(timeout);
    //     } else {
    //       //否则重连
    //       this.initWebSocket();
    //     }
    //     self.serverTimeoutObj = setTimeout(function () {
    //       //超时关闭
    //       this.websock.close();
    //     }, self.timeout);
    //   }, self.timeout);
    // },
    //  连接建立之后执行send方法发送数据
    websocketonopen() {
      var objn = JSON.stringify({
        cno: this.cno,
        msgcode: "201",
        source: "200116212001230",
        token: this.loginToken,
      });
      this.websocketsend(objn);
    },
    //  数据接收
    websocketonmessage(e) {
      console.log("服务器返回:" + e.data);
      var str = e.data;
      var strname = JSON.parse(str);
      if (e.data && strname.msgcode != 100) {
        var messagelist = JSON.stringify({
          cno: this.cno,
          msgcode: 200,
          source: strname.msgno,
          sourcecode: strname.msgcode,
          token: this.loginToken,
        });
        this.websocketsend(messagelist);
      }
      if (strname.code == 500) {
        // alert("消息参数错误!");
        this.$message.error(strname.message);
        console.log("this.$route:", this.$route);
        let loginuser = JSON.parse(localStorage.getItem("loginuser"));
        let loginuserId = loginuser.id;
        let loginuserName = loginuser.userName;
        axios
          .get("/too/center/weblog/save", {
            params: {
              callback: "",
              errorCode: strname.code,
              errorExplain: strname.message,
              interfaceUrl: this.websock.url,
              menuName: "外呼中心-websocket连接",
              menuUrl: this.$route.path,
              params: strname,
              source: "外呼中心",
              userId: loginuserId,
              userName: loginuserName,
            },
          })
          .then((res) => {
            console.log(res, "成功");
          })
          .catch((error) => {
            console.log(error, "失败");
          });
        return false;
      } else if (strname.code == 10000) {
        this.$router.push("/");
        this.$message.error(strname.message);
        clearInterval(this.timer);
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer4);
        clearInterval(this.timer6);
        let loginuser = JSON.parse(localStorage.getItem("loginuser"));
        let loginuserId = loginuser.id;
        let loginuserName = loginuser.userName;
        axios
          .get("/too/center/weblog/save", {
            params: {
              callback: "",
              errorCode: strname.code,
              errorExplain: strname.message,
              interfaceUrl: this.websock.url,
              menuName: "外呼中心-websocket连接",
              menuUrl: this.$route.path,
              params: strname,
              source: "外呼中心",
              userId: loginuserId,
              userName: loginuserName,
            },
          })
          .then((res) => {
            console.log(res, "成功");
          })
          .catch((error) => {
            console.log(error, "失败");
          });
      }
      if (strname.msgcode == 112) {
        this.strcodes = strname.msgcode;
        this.pNumbers = strname.phone;
        this.strPhone = strname.phone;
        this.strUser = strname.userid;
        this.userId = strname.userid;
        this.strequipmentId = strname.equipmentId;
        this.equipmentIds = strname.equipmentId;
        this.strMessage = strname.message;
        this.mscode = strname.msgcode;
        this.shout();
        localStorage.setItem("userIds", JSON.stringify(this.strUser));
        localStorage.setItem(
          "equMemberId",
          JSON.stringify(this.equipmentIdStr)
        );
        this.imgIcon = false;
        this.imgIcons = true;
        this.StandbyIcon = false;
        this.StandbyIcons = true;
        this.imgIconBtnOne = false;
        this.imgIconBtns = false;
      } else if (strname.msgcode == 120) {
        this.imgIcon = false;
        this.imgIcons = true;
        this.StandbyIcon = false;
        this.StandbyIcons = true;
        this.imgIconBtnOne = false;
        this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
        // this.$refs.RefgetList.disableds = true;
        // this.$refs.RefMemberIds.disableds = true;
        this.endReason();
        this.isAnswer = false;
        this.isListion = true;
        this.isListioning = false;
        this.dateTime();
      } else if (strname.msgcode == 130) {
        this.mainUniqueIds = strname.mainUniqueId;
        localStorage.setItem("UniqueIdn", JSON.stringify(this.mainUniqueIds));
        console.log(this.mainUniqueIds, "xinjiayi123");
        this.phoneID();
      } else if (strname.msgcode == 101) {
        // clearInterval(this.timer3);
        console.log(this.cnoStatus, "输出状态查看一下");
        if (strname.status == "1" || strname.status == 1) {
          this.valuezhuangtai = "忙碌";
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          this.cnoStatus = 1;
          this.imgIcon = true;
          this.imgIcons = false;
          this.StandbyIcon = true;
          this.StandbyIcons = false;
          // this.imgJinJi = false;
          // console.log(this.imgJinJi, "false");
          this.imgIconBtnOne = true;
          // this.isArrangement = true;
          // this.isArrangement1 = false;
        } else {
          this.valuezhuangtai = "空闲";
          this.cnoStatus = strname.status;
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // this.isArrangement = true;
          // this.isArrangement1 = false;
          this.isAnswer = false;
          this.isListion = true;
          this.isListioning = false;
          // this.PersonstatusIng = false;
          //铃不动
          clearInterval(this.timer);
          this.$refs.audioLing.pause();
          // this.isQues = false;
          // this.isQues1 = false;
        }
      }
      if (strname.msgcode == 111 || strname.msgcode == "111") {
        this.shout();
        //铃响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.isListion = true;
        this.isListioning = false;
        if (this.isAnswer == true) {
          this.isAnswer = false;
        }
        localStorage.removeItem("phoneNumber");
        this.cnoStatus = 1;
        console.log("走到这一步0000");
      }
      this.strUser = strname.userid;
      // this.resets();
      this.strcode = strname.code;
      console.log("服务器返回:" + strname);

      this.textPhone = strname.phone;
      this.phoneOne = strname.phone;
      this.strequipmentId = strname.equipmentId;
      this.strMessage = strname.message;
      this.strendReason = strname.endReason;
      this.mscode = strname.msgcode;
      this.mainUniqueIds = strname.mainUniqueId;
      localStorage.setItem("strnamePhone", JSON.stringify(strname.phone));
      // this.resets();
      console.log(this.mainUniqueIds, "xinjiayi123");
      this.strcode = strname.code;
      console.log("服务器返回:" + strname);
    },
    //  数据发送
    websocketsend(Data) {
      this.websock.send(Data);
      console.log(`数据发送` + Data);
    },
    websocketonerror() {
      console.log("连接失败重新连接");
      // this.initWebSocket();
    },
    //  关闭
    websocketclose(e) {
      console.log("断开连接", e);
      var str = e.data;
      var strname = JSON.parse(str);
      let loginuser = JSON.parse(localStorage.getItem("loginuser"));
      let loginuserId = loginuser.id;
      let loginuserName = loginuser.userName;
      axios
        .get("/too/center/weblog/save", {
          params: {
            callback: "",
            errorCode: strname.code,
            errorExplain: strname.message,
            interfaceUrl: this.websock.url,
            menuName: "外呼中心-websocket连接",
            menuUrl: this.$route.path,
            params: strname,
            source: "外呼中心",
            userId: loginuserId,
            userName: loginuserName,
          },
        })
        .then((res) => {
          console.log(res, "成功");
        })
        .catch((error) => {
          console.log(error, "失败");
        });
      this.reconnect();
    },
    //客服状态的修改
    myStatus(value) {
      // console.log('=====',value);
      // console.log("=====", value);
      console.log("ceshi测试111111111111111111");
      this.cnoStatus = value;
      apiShout.getUpStatus({ cno: this.cno, status: value }).then((res) => {
        console.log("修改状态：", res.data);
        if (value == "0" && res.data.code == 200) {
          //调用一次挂断接口
          // this.endReason();
          //空闲
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // this.timer3 = setInterval(this.shout, 3000);
          // this.shout();
        } else if (value == "1" && res.data.code == 200) {
          //忙碌
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // clearInterval(this.timer3);
        } else if (value == "2" && res.data.code == 200) {
          //暂离
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // clearInterval(this.timer3);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //紧急联系人
    emergencyContact(val) {
      console.log(this.ursents, "输出对");
      for (var i = 0; i < this.ursents.length; i++) {
        this.userPhone = this.ursents[i];
        if (val === this.userPhone.userName) {
          this.formMember.emergencyContactOnePhone = this.userPhone.phone;
          let str = this.formMember.emergencyContactOnePhone;
          this.formMember.emergencyContactOnePhone = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
          //对于电话的处理
        }
      }
    },
    //紧急联系人2
    emergencyContacts(val) {
      console.log(this.ursentsTwo, "输出对");
      for (var i = 0; i < this.ursentsTwo.length; i++) {
        this.userPhones = this.ursentsTwo[i];
        if (val === this.userPhones.userName) {
          this.formMember.emergencyContactTwoPhone = this.userPhones.phone;
          let str = this.formMember.emergencyContactTwoPhone;
          this.formMember.emergencyContactTwoPhone = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
          //对于电话的处理
        }
      }
    },
    dateTime() {
      //获取通话记录
      let date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      this.timeDateShow = year + seperator1 + month + seperator1 + strDate;
      console.log(this.timeDateShow, "输出当前时间");
      apiShout.Wconverse({ time: this.timeDateShow }).then((res) => {
        //console.log("通话记录：", res.data);
        if (res.data.code === 200) {
          // 对通话时长的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].bridgeDuration == null) {
              res.data.result.data[i].bridgeDuration = "";
            } else {
              res.data.result.data[i].bridgeDuration = this.format(
                res.data.result.data[i].bridgeDuration
              );
            }
          }
          this.tableData1 = res.data.result.data;
          this.totalTime1 = this.format(res.data.result.totalTime);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //静音
    jinyin() {
      this.muteCount++;
      if (this.muteCount % 2 === 0) {
        this.isYIN = true;
        this.isJIN = false;
        //取消静音
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.direction = "in";
          ClinkAgent.unmute(params);
        });
      } else {
        this.isJIN = true;
        this.isYIN = false;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.direction = "in";
          ClinkAgent.mute(params);
        });
      }
    },
    timeDate(val) {
      var seperator1 = "-";
      var year = val.getFullYear();
      var month = val.getMonth() + 1;
      var strDate = val.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      val = year + seperator1 + month + seperator1 + strDate;
      console.log(val, "输出日期格式1");
      apiShout.Wconverse({ time: val }).then((res) => {
        //console.log("通话记录：", res.data);
        if (res.data.code === 200) {
          // 对通话时长的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].bridgeDuration == null) {
              res.data.result.data[i].bridgeDuration = "";
            } else {
              res.data.result.data[i].bridgeDuration = this.format(
                res.data.result.data[i].bridgeDuration
              );
            }
          }
          this.tableData1 = res.data.result.data;
          this.totalTime1 = this.format(res.data.result.totalTime);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    regionOne(val) {
      if (this.cnoStatus != 0 || this.cnoStatus != "0") {
        this.$message.error("当前忙碌，请稍等再试！");
        return false;
      } else {
        var objmegThree = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: val,
          userid: this.elderlyId,
          equipmentid: null,
          calltype: "4",
        });
        this.websocketsend(objmegThree);
        // this.phones = val;
        this.calltypes = "4";
        this.UniqueIds = "";
        if (val != null) {
          this.size = "呼叫中";
          //记时通话时间
          clearInterval(this.timer2);
          this.currentTime1 = 0;
          this.testTimer1();
          //客服状态置忙
          this.valuezhuangtai = "忙碌";
          this.cnoStatus = 1;
          //改变客服状态下的计时器
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          this.imgIconBtnOne = true;
          this.imgIconBtns = false;
          let hh = val;
          this.allPhone = val;
          // //回拨前调用的接口
          // let backObj = {
          //   con: this.cno,
          //   phone: hh,
          //   userId: this.backUserId,
          // };
          // apiShout.getbackInfor(backObj).then((res) => {
          //   if (res.data.code === 200) {
          //     console.log("回拨前调用的接口：", res.data);
          //   } else {
          //     this.$message.error(res.data.msg);
          //   }
          // });
          if (this.allPhone != null) {
            const audioElement = this.audioElement;
            if (audioElement && audioElement.length > 0) {
              for (let i = 0; i < audioElement.length; i++) {
                this.audioElement[i].pause();
              }
            }
          }
          //存储本地
          localStorage.setItem("phone", JSON.stringify(val));
          localStorage.setItem("phoneNumber", JSON.stringify(val));
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.tel = hh;
            ClinkAgent.previewOutcall(params);
            ClinkAgent.registerListener(
              ClinkAgent.EventType.PREVIEW_OUTCALL,
              function (token) {
                console.log("外呼结果：", token);
              }
            ); // 预览外呼
          });
          this.isListioning = true;
          this.isListion = false;
          // //请求电话通话记录ID
          // apiShout.getcallHistory({ phone: val }).then((res) => {
          //   console.log("电话通话记录", res.data);
          //   this.mainUniqueId=res.data.result;
          //   console.log('main:',this.mainUniqueId);
          // });
          //请求挂机接口
          // this.timer5 = setInterval(this.endReason, 1000);
        } else {
          this.imgIconBtns = true;
          this.imgIconBtnOne = true;
        }
        console.log(val, "123");
      }
    },
    //点击表格的每一行事件
    rowClick(row, column, event) {
      debugger;
      console.log(this.formMember.sparePhone, "000000000");
      this.UniqueIds = row.mainUniqueId;
      localStorage.setItem("UniqueIdn", JSON.stringify(row.mainUniqueId));
      this.customerId = row.customerId;
      this.equipmentId = row.equipmentId;
      //回拨前的参数
      this.backUserId = row.customerId;
      // this.endReason();
      console.log("点击表格事件：", row);
      // console.log(row, "row123");
      // console.log(column, "column123");
      // console.log(event, "event123");
      this.phones = row.sparePhone;
      this.localPhone = row.localPhone;
      this.allPhone = row.localPhone;
      // console.log("this.local:",this.allPhone);
      if (this.isJIN == true) {
        this.muteCount = 0;
        this.isYIN = true;
        this.isJIN = false;
      }
      if (this.localPhone != null) {
        this.imgIcon = true;
        this.imgIcons = false;
        this.imgbeiyong = true;
        this.imgbeiyongs = false;
      } else {
        this.imgIcons = true;
        this.imgIcon = false;
        this.imgbeiyong = false;
        this.imgbeiyongs = true;
      }
      this.customerIds = row.customerId;
      this.equipmentIds = row.equipmentId;
      if (this.isListioning == false) {
        if (this.equipmentIds == null) {
          this.formMember = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.valueLive = "";
          this.valuezhiLi = "";
          this.valuexingge = "";
          this.valueyuyan = "";
          this.valuetingli = "";
          this.valueshili = "";
          this.valuexingdong = "";
          this.valuemanxing = "";
          this.county = "";
          this.city = "";
          this.historyList = "";
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
          // this.region = "无数据";
          // this.region.forEach(item=>{
          //   item.label = "无添加紧急联系人"
          // })
          // this.region.label = "无数据"
        } else {
          //查询用户信息
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((dat) => {
            console.log("用户信息：", dat.data);
            this.userNames = dat.data.result.member.name;
            if (dat.data.code === 200) {
              //对于生日的处理
              let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday = y + "-" + m + "-" + d;
              //对于激活日期的处理
              let b = dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
              //对于到期日期的处理
              let c = dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
              this.formMember = dat.data.result.member;
              this.cityName = dat.data.result.member.city;
              this.countyName = dat.data.result.member.county;
              this.elderlyId = dat.data.result.member.id;
              // let arrList = [
              //   {
              //     value: this.formMember.emergencyContactOnePhone,
              //     label: this.formMember.emergencyContactOne,
              //   },
              //   {
              //     value: this.formMember.emergencyContactTwoPhone,
              //     label: this.formMember.emergencyContactTwo,
              //   },
              //   {
              //     value: this.formMember.emergencyContactThreePhone,
              //     label: this.formMember.emergencyContactThree,
              //   },
              // ];
              this.imgIconBtns = false;
              this.imgIconBtnOne = true;
              // this.region = arrList;
              // console.log(this.region, "输出region");
              // this.region.forEach((element) => {
              //   this.region.label = element.label;
              //   this.region.value = element.value;
              //   console.log(
              //     this.region.value,
              //     this.region.label,
              //     "this.region.value1",
              //     "this.region.label2"
              //   );
              // });
              // console.log(arrList,"输出一下arrList")
              // console.log(CodeToText[this.countyName], "CodeToText123");
              // console.log(CodeToText[this.cityName], "CodeToText11111");
              this.city = CodeToText[this.cityName];
              this.county = CodeToText[this.countyName];
              //formMember.province （省v-model绑定的字段）
              //this.historyProvince（省的下拉框数据）
              //item.id - item.name （value和label绑定的id和name）
              //historyOrderCity-CityList（省下的市下拉框数据字段）
              // let provinceCode = this.formMember.province;
              // let cityCode = this.city;
              // let CityList = [];
              // let countyList = []; //historycounty
              // this.historyProvince.forEach((item) => {
              //   if (item.id == provinceCode) {
              //     CityList = item.name;
              //   }
              // });
              // this.historyOrderCity.forEach((item) => {
              //   if (item.id == cityCode) {
              //     countyList = item.name;
              //   }
              // });
              // this.historyOrderCity = CityList;
              // this.historycounty = countyList;
              this.familyIds = dat.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              console.log(this.familyIds, "家庭id");
              this.ursents = [];
              this.ursentsTwo = [];
              this.region = [];
              this.regions = "";
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res, "紧急联系人");
                  // this.ursents = res.data.result
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    this.region.push(item);
                    console.log(this.ursents, "输出下拉框");
                    console.log(this.region, "紧急联系人111111");
                  });
                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[i];
                    if (this.userPhone.id === this.emergencyOnes) {
                      this.formMember.emergencyContactOnePhone =
                        this.userPhone.phone;
                      this.formMember.emergencyContactOne =
                        this.userPhone.userName;
                      let str = this.formMember.emergencyContactOnePhone;
                      this.formMember.emergencyContactOnePhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                  }
                  for (let i = 0; i < this.ursentsTwo.length; i++) {
                    this.userPhones = this.ursentsTwo[i];
                    if (this.userPhones.id === this.emergencyTwos) {
                      this.formMember.emergencyContactTwoPhone =
                        this.userPhones.phone;
                      this.formMember.emergencyContactTwo =
                        this.userPhones.userName;
                      let str = this.formMember.emergencyContactTwoPhone;
                      this.formMember.emergencyContactTwoPhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
              this.formMember.birthday = dat.data.result.member.birthday;
              this.valueLive = dat.data.result.memberInfo.live;
              this.valuezhiLi = dat.data.result.memberInfo.intellect;
              this.valuexingge = dat.data.result.memberInfo.character;
              this.valueyuyan = dat.data.result.memberInfo.language;
              this.valuetingli = dat.data.result.memberInfo.hearing;
              this.valueshili = dat.data.result.memberInfo.vision;
              this.valuexingdong = dat.data.result.memberInfo.action;
              this.valuemanxing = dat.data.result.memberInfo.illness;
              //健康数据
              this.forBody = dat.data.result.memberInfo;
              for (let i = 0; i < dat.data.result.remind.length; i++) {
                this.morningbefor = dat.data.result.remind[0];
                this.morningafter = dat.data.result.remind[1];
                this.lunchbefor = dat.data.result.remind[2];
                this.lunchafter = dat.data.result.remind[3];
                this.eveningbefor = dat.data.result.remind[4];
              }
              // this.formMember.sparePhone =
              //   this.formMember.sparePhone.substr(0, 3) +
              //   "****" +
              //   this.formMember.sparePhone.substr(7);
              let str = this.formMember.sparePhone;
              this.formMember.NewsparePhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              this.sparePhoneTwo = dat.data.result.member.sparePhone;
              //对于电话的处理
              dat.data.result.member.phone =
                dat.data.result.member.phone.substr(0, 3) +
                "****" +
                dat.data.result.member.phone.substr(7);
              // dat.data.result.member.sparePhone =
              //   dat.data.result.member.sparePhone.substr(0, 3) +
              //   "****" +
              //   dat.data.result.member.sparePhone.substr(7);
              dat.data.result.member.emergencyContactOnePhone =
                dat.data.result.member.emergencyContactOnePhone.substr(0, 3) +
                "****" +
                dat.data.result.member.emergencyContactOnePhone.substr(7);
              dat.data.result.member.emergencyContactTwoPhone =
                dat.data.result.member.emergencyContactTwoPhone.substr(0, 3) +
                "****" +
                dat.data.result.member.emergencyContactTwoPhone.substr(7);
              dat.data.result.member.emergencyContactThreePhone =
                dat.data.result.member.emergencyContactThreePhone.substr(0, 3) +
                "****" +
                dat.data.result.member.emergencyContactThreePhone.substr(7);
            } else {
              this.$message.error(dat.data.msg);
            }
          });
          this.historyOrderUser();
        }
      } else if (this.isListioning == false && this.isListion == true) {
        if (this.equipmentIds == null) {
          this.formMember = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.valueLive = "";
          this.valuezhiLi = "";
          this.valuexingge = "";
          this.valueyuyan = "";
          this.valuetingli = "";
          this.valueshili = "";
          this.valuexingdong = "";
          this.valuemanxing = "";
          this.county = "";
          this.city = "";
          this.historyList = "";
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
        } else {
          //查询用户信息
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((dat) => {
            console.log("用户信息：", dat.data);
            if (dat.data.code === 200) {
              //对于生日的处理
              let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday = y + "-" + m + "-" + d;
              //对于激活日期的处理
              let b = dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
              //对于到期日期的处理
              let c = dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
              this.formMember = dat.data.result.member;
              this.cityName = dat.data.result.member.city;
              this.countyName = dat.data.result.member.county;
              this.elderlyId = dat.data.result.member.id;
              // console.log(CodeToText[this.countyName], "CodeToText123");
              // console.log(CodeToText[this.cityName], "CodeToText11111");
              this.city = CodeToText[this.cityName];
              this.county = CodeToText[this.countyName];
              // let arrList = [
              //   {
              //     value: this.formMember.emergencyContactOnePhone,
              //     label: this.formMember.emergencyContactOne,
              //   },
              //   {
              //     value: this.formMember.emergencyContactTwoPhone,
              //     label: this.formMember.emergencyContactTwo,
              //   },
              //   {
              //     value: this.formMember.emergencyContactThreePhone,
              //     label: this.formMember.emergencyContactThree,
              //   },
              // ];
              this.imgIconBtns = false;
              this.imgIconBtnOne = true;
              // this.region = arrList;
              console.log(this.region, "输出region");
              // this.region.forEach((element) => {
              //   this.region.label = element.label;
              //   this.region.value = element.value;
              //   console.log(
              //     this.region.value,
              //     this.region.label,
              //     "this.region.value1",
              //     "this.region.label2"
              //   );
              // });
              //formMember.province （省v-model绑定的字段）
              //this.historyProvince（省的下拉框数据）
              //item.id - item.name （value和label绑定的id和name）
              //historyOrderCity-CityList（省下的市下拉框数据字段）
              // let provinceCode = this.formMember.province;
              // let cityCode = this.city;
              // let CityList = [];
              // let countyList = []; //historycounty
              // this.historyProvince.forEach((item) => {
              //   if (item.id == provinceCode) {
              //     CityList = item.name;
              //   }
              // });
              // this.historyOrderCity.forEach((item) => {
              //   if (item.id == cityCode) {
              //     countyList = item.name;
              //   }
              // });
              // this.historyOrderCity = CityList;
              // this.historycounty = countyList;
              let str = this.formMember.sparePhone;
              this.formMember.NewsparePhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              this.familyIds = dat.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              console.log(this.familyIds, "家庭id");
              this.ursents = [];
              this.ursentsTwo = [];
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res, "紧急联系人");
                  // this.ursents = res.data.result
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    this.region.push(item);
                    console.log(this.ursents, "输出下拉框");
                    console.log(this.region, "紧急联系人111111");
                  });

                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[i];
                    if (this.userPhone.id === this.emergencyOnes) {
                      this.formMember.emergencyContactOnePhone =
                        this.userPhone.phone;
                      this.formMember.emergencyContactOne =
                        this.userPhone.userName;
                      let str = this.formMember.emergencyContactOnePhone;
                      this.formMember.emergencyContactOnePhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                  }
                  for (let i = 0; i < this.ursentsTwo.length; i++) {
                    this.userPhones = this.ursentsTwo[i];
                    if (this.userPhones.id === this.emergencyTwos) {
                      this.formMember.emergencyContactTwoPhone =
                        this.userPhones.phone;
                      this.formMember.emergencyContactTwo =
                        this.userPhones.userName;
                      let str = this.formMember.emergencyContactTwoPhone;
                      this.formMember.emergencyContactTwoPhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
              this.formMember.birthday = dat.data.result.member.birthday;
              this.valueLive = dat.data.result.memberInfo.live;
              this.valuezhiLi = dat.data.result.memberInfo.intellect;
              this.valuexingge = dat.data.result.memberInfo.character;
              this.valueyuyan = dat.data.result.memberInfo.language;
              this.valuetingli = dat.data.result.memberInfo.hearing;
              this.valueshili = dat.data.result.memberInfo.vision;
              this.valuexingdong = dat.data.result.memberInfo.action;
              this.valuemanxing = dat.data.result.memberInfo.illness;
              //健康数据
              this.forBody = dat.data.result.memberInfo;
              for (let i = 0; i < dat.data.result.remind.length; i++) {
                this.morningbefor = dat.data.result.remind[0];
                this.morningafter = dat.data.result.remind[1];
                this.lunchbefor = dat.data.result.remind[2];
                this.lunchafter = dat.data.result.remind[3];
                this.eveningbefor = dat.data.result.remind[4];
              }
              //对于电话的处理
              dat.data.result.member.phone =
                dat.data.result.member.phone.substr(0, 3) +
                "****" +
                dat.data.result.member.phone.substr(7);
              // dat.data.result.member.sparePhone =
              //   dat.data.result.member.sparePhone.substr(0, 3) +
              //   "****" +
              //   dat.data.result.member.sparePhone.substr(7);
              dat.data.result.member.emergencyContactOnePhone =
                dat.data.result.member.emergencyContactOnePhone.substr(0, 3) +
                "****" +
                dat.data.result.member.emergencyContactOnePhone.substr(7);
              dat.data.result.member.emergencyContactTwoPhone =
                dat.data.result.member.emergencyContactTwoPhone.substr(0, 3) +
                "****" +
                dat.data.result.member.emergencyContactTwoPhone.substr(7);
              dat.data.result.member.emergencyContactThreePhone =
                dat.data.result.member.emergencyContactThreePhone.substr(0, 3) +
                "****" +
                dat.data.result.member.emergencyContactThreePhone.substr(7);
            } else {
              this.$message.error(dat.data.msg);
            }
          });
          this.historyOrderUser();
        }
      } else if (this.isListioning == true) {
        event.isTrusted = false;
      }
      // console.log(this.equipmentIds,"输出equipmentIds")

      // console.log("=====", row, column, event);
      // this.name = row.name;
      // this.phone = row.phone;
    },
    rowBtn(row, column, event) {
      debugger;
      const audioElement = this.audioElement;
      if (audioElement && audioElement.length > 0) {
        for (let i = 0; i < audioElement.length; i++) {
          this.audioElement[i].pause();
        }
      }
      console.log(row, "rowrowrow");
      console.log(column, "columncolumn");
      console.log(event, "eventevent");
      this.customerIds = row.customerId;
      this.equipmentId = row.equipmentId;
      if (this.isListioning == false) {
        this.prohibit = event.cancelable;
        if (row.customerNumber == null) {
          this.formMember = {};
          this.formMembers = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.valueLive = "";
          this.valuezhiLi = "";
          this.valuexingge = "";
          this.valueyuyan = "";
          this.valuetingli = "";
          this.valueshili = "";
          this.valuexingdong = "";
          this.valuemanxing = "";
          this.county = "";
          this.city = "";
          this.historyList = [];
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
        } else {
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((res) => {
            if (res.data.code === 200) {
              if (
                res.data.result.member == null ||
                res.data.result.memberInfo == null
              ) {
                this.formMember = {};
                this.formMembers = {};
                this.formMemberInfo = {};
                this.forBody = {};
                this.formMember.birthday = "";
                this.valueLive = "";
                this.valuezhiLi = "";
                this.valuexingge = "";
                this.valueyuyan = "";
                this.valuetingli = "";
                this.valueshili = "";
                this.valuexingdong = "";
                this.valuemanxing = "";
                this.county = "";
                this.city = "";
                this.historyList = [];
              } else {
                console.log(res, "手机号查询用户信息");
                this.formMember = res.data.result.member;
                // this.formMember = row.memberInfo;
                this.formMembers = res.data.result.memberInfo;
                this.formMemberInfo = res.data.result.memberInfo;
                this.forBody = res.data.result.memberInfo;
                // let arrList = [
                //   {
                //     value: this.formMember.emergencyContactOnePhone,
                //     label: this.formMember.emergencyContactOne,
                //   },
                //   {
                //     value: this.formMember.emergencyContactTwoPhone,
                //     label: this.formMember.emergencyContactTwo,
                //   },
                //   {
                //     value: this.formMember.emergencyContactThreePhone,
                //     label: this.formMember.emergencyContactThree,
                //   },
                // ];
                this.imgIconBtns = false;
                this.imgIconBtnOne = true;
                // this.region = arrList;
                // console.log(this.region, "输出region");
                // this.region.forEach((element) => {
                //   this.region.label = element.label;
                //   this.region.value = element.value;
                //   console.log(
                //     this.region.value,
                //     this.region.label,
                //     "this.region.value1",
                //     "this.region.label2"
                //   );
                // });
                let str = this.formMember.sparePhone;
                this.formMember.NewsparePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
                this.familyIds = res.data.result.member.familyId;
                console.log(this.familyIds, "123123123");
                this.emergencyOnes = res.data.result.member.emergencyOne;
                this.emergencyTwos = res.data.result.member.emergencyTwo;
                this.elderlyId = res.data.result.member.id;
                console.log(this.familyIds, "家庭id");
                this.ursents = [];
                this.ursentsTwo = [];
                apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                  if (res.data.code === 200) {
                    console.log(res, "紧急联系人");
                    // this.ursents = res.data.result
                    res.data.result.forEach((item) => {
                      this.ursents.push(item);
                      this.ursentsTwo.push(item);
                      console.log(this.ursents, "输出下拉框");
                    });

                    for (var i = 0; i < this.ursents.length; i++) {
                      this.userPhone = this.ursents[i];
                      if (this.userPhone.id === this.emergencyOnes) {
                        this.formMember.emergencyContactOnePhone =
                          this.userPhone.phone;
                        this.formMember.emergencyContactOne =
                          this.userPhone.userName;
                        let str = this.formMember.emergencyContactOnePhone;
                        this.formMember.emergencyContactOnePhone = str.replace(
                          /(\d{3})\d{4}(\d{1})/,
                          "$1****$2"
                        );
                      }
                    }
                    for (let i = 0; i < this.ursentsTwo.length; i++) {
                      this.userPhones = this.ursentsTwo[i];
                      if (this.userPhones.id === this.emergencyTwos) {
                        this.formMember.emergencyContactTwoPhone =
                          this.userPhones.phone;
                        this.formMember.emergencyContactTwo =
                          this.userPhones.userName;
                        let str = this.formMember.emergencyContactTwoPhone;
                        this.formMember.emergencyContactTwoPhone = str.replace(
                          /(\d{3})\d{4}(\d{1})/,
                          "$1****$2"
                        );
                      }
                    }
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
                this.cityName = res.data.result.member.city;
                this.countyName = res.data.result.member.county; //TextToCode
                this.formMember.city = CodeToText[this.cityName];
                this.formMember.county = CodeToText[this.countyName];
                this.formMember.birthday = res.data.result.member.birthday;
                this.valueLive = res.data.result.memberInfo.live;
                this.valuezhiLi = res.data.result.memberInfo.intellect;
                this.valuexingge = res.data.result.memberInfo.character;
                this.valueyuyan = res.data.result.memberInfo.language;
                this.valuetingli = res.data.result.memberInfo.hearing;
                this.valueshili = res.data.result.memberInfo.vision;
                this.valuexingdong = res.data.result.memberInfo.action;
                this.valuemanxing = res.data.result.memberInfo.illness;
                // this.formMember.sparePhone =
                //   this.formMember.sparePhone.substr(0, 3) +
                //   "****" +
                //   this.formMember.sparePhone.substr(7);
                //健康数据
                this.forBody = res.data.result.memberInfo;
                for (let i = 0; i < res.data.result.remind.length; i++) {
                  this.morningbefor = res.data.result.remind[0];
                  this.morningafter = res.data.result.remind[1];
                  this.lunchbefor = res.data.result.remind[2];
                  this.lunchafter = res.data.result.remind[3];
                  this.eveningbefor = res.data.result.remind[4];
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          this.historyOrderUser();
        }
      }
      if (this.isListioning == false && this.isListion == true) {
        this.prohibit = event.cancelable;
        if (row.customerNumber == null) {
          this.formMember = {};
          this.formMembers = {};
          this.formMemberInfo = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.valueLive = "";
          this.valuezhiLi = "";
          this.valuexingge = "";
          this.valueyuyan = "";
          this.valuetingli = "";
          this.valueshili = "";
          this.valuexingdong = "";
          this.valuemanxing = "";
          this.county = "";
          this.city = "";
          this.historyList = [];
        } else {
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((res) => {
            if (res.data.code === 200) {
              console.log(res, "手机号查询用户信息");
              this.formMember = res.data.result.member;
              // this.formMember = row.memberInfo;
              this.familyIds = res.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = res.data.result.member.emergencyOne;
              this.emergencyTwos = res.data.result.member.emergencyTwo;
              console.log(this.familyIds, "家庭id");
              this.elderlyId = res.data.result.member.id;
              this.ursents = [];
              this.ursentsTwo = [];
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res, "紧急联系人");
                  // this.ursents = res.data.result
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    console.log(this.ursents, "输出下拉框");
                  });

                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[i];
                    if (this.userPhone.id === this.emergencyOnes) {
                      this.formMember.emergencyContactOnePhone =
                        this.userPhone.phone;
                      this.formMember.emergencyContactOne =
                        this.userPhone.userName;
                      let str = this.formMember.emergencyContactOnePhone;
                      this.formMember.emergencyContactOnePhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                  }
                  for (let i = 0; i < this.ursentsTwo.length; i++) {
                    this.userPhones = this.ursentsTwo[i];
                    if (this.userPhones.id === this.emergencyTwos) {
                      this.formMember.emergencyContactTwoPhone =
                        this.userPhones.phone;
                      this.formMember.emergencyContactTwo =
                        this.userPhones.userName;
                      let str = this.formMember.emergencyContactTwoPhone;
                      this.formMember.emergencyContactTwoPhone = str.replace(
                        /(\d{3})\d{4}(\d{1})/,
                        "$1****$2"
                      );
                    }
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
              let str = this.formMember.sparePhone;
              this.formMember.NewsparePhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              this.formMembers = res.data.result.memberInfo;
              this.formMemberInfo = res.data.result.memberInfo;
              this.forBody = res.data.result.memberInfo;
              this.cityName = res.data.result.member.city;
              this.countyName = res.data.result.member.county; //TextToCode
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              this.formMember.birthday = res.data.result.member.birthday;
              this.valueLive = res.data.result.memberInfo.live;
              this.valuezhiLi = res.data.result.memberInfo.intellect;
              this.valuexingge = res.data.result.memberInfo.character;
              this.valueyuyan = res.data.result.memberInfo.language;
              this.valuetingli = res.data.result.memberInfo.hearing;
              this.valueshili = res.data.result.memberInfo.vision;
              this.valuexingdong = res.data.result.memberInfo.action;
              this.valuemanxing = res.data.result.memberInfo.illness;
              // this.formMember.sparePhone =
              //   this.formMember.sparePhone.substr(0, 3) +
              //   "****" +
              //   this.formMember.sparePhone.substr(7);
              //健康数据
              this.forBody = res.data.result.memberInfo;
              for (let i = 0; i < res.data.result.remind.length; i++) {
                this.morningbefor = res.data.result.remind[0];
                this.morningafter = res.data.result.remind[1];
                this.lunchbefor = res.data.result.remind[2];
                this.lunchafter = res.data.result.remind[3];
                this.eveningbefor = res.data.result.remind[4];
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          this.historyOrderUser();
        }
      } else if (this.isListioning == true) {
        event.isTrusted = false;
      }
    },
    //市
    provinces(val) {
      this.ids = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        console.log(res);
        // this.historyOrderCity = res.data.result;
        this.historyOrderCity = res.data.result; //渲染省
      });
    },
    //区
    provincesOne(val) {
      this.ids = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        // this.historyOrderCity = res.data.result;
        this.historycounty = res.data.result; //渲染省
      });
    },
    //模糊搜索
    handlChange(val) {
      if (this.searchs !== "") {
        this.dataLists = "";
        //获取常用知识
        apiShout.getKnowledge({ text: val }).then((res) => {
          //console.log("常用知识：", res.data);
          if (res.data.code === 200) {
            // this.dataList = res.data.result;
            this.dataLists = res.data.result[0].childs[0].text;
          } else {
            this.$message.error(res.data.msg);
          }
        });
        console.log(apiShout.getKnowledge(), "清空搜索框展示数据");
      } else {
        this.offenKnow();
      }
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
      // return data.text.indexOf(value) !== -1
    },
    // 播放音频
    handlePlay(index) {
      this.indexOfs = index;

      if (this.allPhone == null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            if (i === index) {
              this.audioElement[i].play();
            } else {
              this.audioElement[i].pause();
            }
          }
        }
      } else if (this.allPhone != null && this.isListion == true) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            if (i === index) {
              this.audioElement[i].play();
            } else {
              this.audioElement[i].pause();
            }
          }
        }
      } else if (this.allPhone != null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
      }
    },
    //紧急联系人
    imgIconBtn() {
      this.regions = "";
      if (this.equipmentIds == null) {
        this.imgIconBtnOne = true;
        this.imgIconBtns = false;
      } else {
        this.imgIconBtnOne = true;
        this.imgIconBtns = true;
      }
    },
    //备用
    callBackStandby() {
      console.log(this.sparePhoneTwo, "000000000");
      if (this.cnoStatus != 0 || this.cnoStatus != "0") {
        this.$message.error("当前忙碌，请稍等再试！");
        // return false;
      } else {
        var objmegTwo = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: this.sparePhoneTwo,
          userid: this.elderlyId,
          equipmentid: null,
          calltype: "3",
        });
        this.websocketsend(objmegTwo);
        this.calltypes = "3";
        this.UniqueIds = "";
        this.size = "呼叫中";
        //页面跳转
        this.isListion = false;
        this.isListioning = true;
        //记时通话时间
        clearInterval(this.timer2);
        this.currentTime1 = 0;
        this.testTimer1();
        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        // if(this.phones !=null){
        //   let hh = this.phones;
        // }else if(this.this.phoneList!=null){
        //   let hh = this.this.phoneList;
        // }
        let hh = this.sparePhoneTwo;
        this.allPhone = this.sparePhoneTwo;
        // //回拨前调用的接口
        // let backObj = {
        //   con: this.cno,
        //   phone: hh,
        //   userId: this.backUserId,
        // };
        // apiShout.getbackInfor(backObj).then((res) => {
        //   if (res.data.code === 200) {
        //     console.log("回拨前调用的接口：", res.data);
        //   } else {
        //     this.$message.error(res.data.msg);
        //   }
        // });
        if (this.allPhone != null) {
          const audioElement = this.audioElement;
          if (audioElement && audioElement.length > 0) {
            for (let i = 0; i < audioElement.length; i++) {
              this.audioElement[i].pause();
            }
          }
        }
        //存储本地
        localStorage.setItem("phone", JSON.stringify(this.phones));
        localStorage.setItem("phoneNumber", JSON.stringify(this.phones));
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.tel = hh;
          ClinkAgent.previewOutcall(params);
          ClinkAgent.registerListener(
            ClinkAgent.EventType.PREVIEW_OUTCALL,
            function (token) {
              console.log("备用外呼结果：", token);
            }
          ); // 预览外呼
        });
        // //请求电话通话记录ID
        // apiShout.getcallHistory({ phone: this.phones }).then((res) => {
        //   console.log("电话通话记录", res.data);
        //   this.mainUniqueId=res.data.result;
        // });
        //请求挂机接口
        // this.timer5 = setInterval(this.endReason, 1000);
      }
    },
    //回拨事件
    callBackMessage() {
      debugger;
      if (this.cnoStatus != 0 || this.cnoStatus != "0") {
        this.$message.error("当前忙碌，请稍等再试！");
        // return false;
      } else {
        var objmeg = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: this.localPhone,
          userid: this.elderlyId,
          equipmentid: this.equipmentId,
          calltype: "2",
        });
        this.websocketsend(objmeg);
        this.calltypes = "2";
        this.UniqueIds = "";
        this.size = "呼叫中";
        //页面跳转
        setTimeout(() => {
          this.isListioning = true;
        }, 1500);
        this.isListion = false;
        //记时通话时间
        clearInterval(this.timer2);
        this.currentTime1 = 0;
        this.testTimer1();
        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        let hh = this.localPhone;
        this.allPhone = this.localPhone;
        // //回拨前调用的接口
        // let backObj = {
        //   con: this.cno,
        //   phone: hh,
        //   userId: this.backUserId,
        // };
        // apiShout.getbackInfor(backObj).then((res) => {
        //   if (res.data.code === 200) {
        //     console.log("回拨前调用的接口：", res.data);
        //   } else {
        //     this.$message.error(res.data.msg);
        //   }
        // });
        if (this.allPhone != null) {
          const audioElement = this.audioElement;
          if (audioElement && audioElement.length > 0) {
            for (let i = 0; i < audioElement.length; i++) {
              this.audioElement[i].pause();
            }
          }
        }
        //存储本地
        localStorage.setItem("phone", JSON.stringify(this.localPhone));
        localStorage.setItem("phoneNumber", JSON.stringify(this.localPhone));
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.tel = hh;
          ClinkAgent.previewOutcall(params);
          ClinkAgent.registerListener(
            ClinkAgent.EventType.KICKOUT,
            function (token) {
              console.log("被踢下线=====================：", token);
              if (token.cno == this.cno) {
                //跳转登录界面
                this.$router.push("/");
                this.$message.error("不好意思，您被踢下线");
                //退出天润的登录
                ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
                  // 传入参数对象
                  var params = {};
                  params.logoutMode = 1;
                  params.removeBinding = 0;
                  ClinkAgent.logout(params);
                });
                clearInterval(this.timer);
                clearInterval(this.timer1);
                clearInterval(this.timer2);
                clearInterval(this.timer3);
                clearInterval(this.timer4);
                // clearInterval(this.timer5);
                clearInterval(this.timer6);
                console.log("判断了-------------");
              }
            }
          ); // 被踢下线
          ClinkAgent.registerListener(
            ClinkAgent.EventType.PREVIEW_OUTCALL,
            function (token) {
              console.log("外呼结果：", token);
            }
          ); // 预览外呼
        });
        // //请求电话通话记录ID
        // apiShout.getcallHistory({ phone: this.localPhone }).then((res) => {
        //   console.log("电话通话记录", res.data);
        //   this.mainUniqueId=res.data.result;
        //   console.log('回拨的main',this.mainUniqueId);
        // });
        //请求挂机接口
        // this.timer5 = setInterval(this.endReason, 1000);
      }
    },
    //挂断电话
    offPhone() {
      if (this.strcode == 500) {
        // this.$message.error("格式错误!");
        return false;
      } else {
        var objs = JSON.stringify({
          cno: this.cno,
          msgcode: 220,
          token: this.loginToken,
          phone: this.allPhone || this.pNumbers || "",
        });
        this.websocketsend(objs);
        //挂断电话
        this.endStatus = "1";
        this.endReason();
        this.endStatus = "0";
        //暂停挂机接口
        // clearInterval(this.timer5);
        this.size = "空闲中";
        let obj = {
          userId: this.elderlyId,
          describe: this.textarea,
          type: this.type,
          typeDetail: this.checkList,
          handleTime: this.handleTime,
          priority: this.priority,
        };
        //存储本地
        localStorage.setItem("order", JSON.stringify(obj));
        // console.log("===", obj);
        //改变客服状态
        this.valuezhuangtai = "空闲";
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        // this.testTimer2();
        //切换上边
        this.isListion = true;
        this.isListioning = false;
        //隐藏接听按钮
        this.isAnswer = false;
        this.isCall = false;
        //暂停接通时的计时器
        clearInterval(this.timer2);
        this.currentTime1 = 0;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.side = 1;
          console.log("走了挂断这一步=======");
          ClinkAgent.unlink(params);
        });
        if (this.isListion == true) {
          this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
        }
        // //请求电话通话记录ID
        // apiShout.getcallHistory({ phone: this.phones }).then((res) => {
        //   console.log("电话通话记录", res.data);
        // });
        //清除本地
        localStorage.removeItem("phone");
        localStorage.removeItem("phoneNumber");
      }
    },
    //关联人员
    Associatedpersonnel() {
      this.dialogVisible = true;
    },
    //创建工单的提交
    finish() {
      console.log(this.mainUniqueIds, "查看是否存在");
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.userId, //关联客服id
        describe: this.textarea,
        type: this.type,
        typeDetail: this.checkListData,
        handleTime: this.valueOrdertime,
        priority: this.valueOrderputong,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        equipmentId: this.equipmentIds,
        callback: this.callback1,
        callPhone: this.allPhone,
        centerType: 2,
        status: "0",
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      //   //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单：", aa.data);
        if (aa.data.code === 200) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          // this.reload();
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.historyOrderUser();
          //清空选中的选项
          this.valueType = false;
          this.textarea = "";
          this.type = "";
          this.checkListData = [];
          this.zz = [];
          this.handleTime = "";
          this.valueOrdertime = "";
          this.valueOrderputong = "";
          this.valueOrder = "";
          this.callback1 = 0;
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish1() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.userId,
        describe: this.textarea,
        type: this.type,
        typeDetail: this.checkListData1,
        handleTime: this.handleTime,
        mainUniqueId: this.UniqueIds,
        centerType: 2,
        status: "0",
        customerId: this.customerIds,
        equipmentId: this.equipmentIds,
        callPhone: "17600182646",
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      console.log(this.UniqueIds, "查看死循环");
      console.log("获取到了电话没：", this.allPhone);
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          // this.reload();
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.law();
          this.historyOrderUser();
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.zz1 = [];
          this.zz2 = [];
          this.checkListData1 = [];
          this.handleTime = "";
          this.handleTime = "";
          this.valueOrder = "";
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish2() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.userId,
        describe: this.textarea,
        type: this.type,
        typeDetail: this.checkListData2,
        handleTime: this.handleTime,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        equipmentId: this.equipmentIds,
        callPhone: this.allPhone,
        centerType: 2,
        status: "0",
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          // this.reload();
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.policy();
          this.historyOrderUser();
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData2 = [];
          this.zz3 = [];
          this.zz4 = [];
          this.handleTime = "";
          this.handleTime = "";
          this.valueOrder = "";
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish3() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.userId,
        describe: this.textarea,
        type: this.type,
        typeDetail: this.checkListData3,
        handleTime: this.handleTime,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        equipmentId: this.equipmentIds,
        callPhone: this.allPhone,
        centerType: 2,
        status: "0",
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          // this.reload();
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.care();
          this.historyOrderUser();
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData3 = [];
          this.zz5 = [];
          this.zz6 = [];
          this.handleTime = "";
          this.valueOrder = "";
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish4() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.userId,
        describe: this.textarea,
        type: this.type,
        typeDetail: this.checkListData4,
        handleTime: this.valueOrdertime1,
        priority: this.valueOrderputong1,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        equipmentId: this.equipmentIds,
        centerType: 2,
        callback: this.callback2,
        callPhone: this.allPhone,
        status: "0",
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          //页面刷新 全部待办  待我办理
          // this.reload();
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          this.backlog();
          this.historyOrderUser();
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData4 = [];
          this.zz7 = [];
          this.zz8 = [];
          this.handleTime = "";
          this.valueOrdertime1 = "";
          this.valueOrderputong1 = "";
          this.valueOrder = "";
          this.valueTypes = false;
          this.callback2 = 0;
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    //请求电话通话记录ID
    phoneID() {
      // console.log("电话通话记录", res.data.result);
      console.log(this.mainUniqueIds, "uniqueidssssss");
      if (this.mainUniqueIds == null || this.mainUniqueIds == "") {
        this.$message.error("获取通话信息中，请稍后提交");
        // this.phoneID();
        return;
      }
      if (this.type == "1") {
        this.finish();
      } else if (this.type == "2") {
        this.finish1();
      } else if (this.type == "3") {
        this.finish2();
      } else if (this.type == "4") {
        this.finish3();
      } else if (this.type == "5") {
        this.finish4();
      }
      // if (res.data.result != "" || res.data.result != null) {
      //   this.mainUniqueId = res.data.result;
      //   console.log(this.mainUniqueId, "电话记录id返回");

      //   // clearInterval(this.timer6);
      // }
    },
    //创建工单的多选
    checkboxChange(e, ib) {
      console.log(e, ib);
      if (e) {
        this.checkListData.push(ib);
      }
      console.log("==", this.checkListData);
      // this.checkList = b;
    },
    checkboxChange1(e, ib) {
      //console.log('---==',b);
      if (e) {
        this.checkListData1.push(ib);
        console.log("==", this.checkListData1);
      }
      // this.checkList1 = b;
    },
    checkboxChange2(e, ib) {
      //console.log('---',b);
      if (e) {
        this.checkListData1.push(ib);
        console.log("==", this.checkListData1);
      }
      // this.checkList2 = b;
    },
    checkboxChange3(e, ib) {
      //console.log('---',b);
      if (e) {
        this.checkListData2.push(ib);
        console.log("==", this.checkListData2);
      }
      // this.checkList3 = b;
    },
    checkboxChange4(e, ib) {
      //console.log('---',b);
      // this.checkList4 = b;
      if (e) {
        this.checkListData2.push(ib);
        console.log("==", this.checkListData2);
      }
    },
    checkboxChange5(e, ib) {
      //console.log('---',b);
      // this.checkList5 = b;
      if (e) {
        this.checkListData3.push(ib);
        console.log("==", this.checkListData3);
      }
    },
    checkboxChange6(e, ib) {
      //console.log('---',b);
      // this.checkList6 = b;
      if (e) {
        this.checkListData3.push(ib);
        console.log("==", this.checkListData3);
      }
    },
    checkboxChange7(e, ib) {
      //console.log('---',b);
      // this.checkList7 = b;
      if (e) {
        this.checkListData4.push(ib);
        console.log("==", this.checkListData4);
      }
    },
    checkboxChange8(e, ib) {
      //console.log('---',b);
      // this.checkList8 = b;
      if (e) {
        this.checkListData4.push(ib);
        console.log("==", this.checkListData4);
      }
    },
    //处理时间
    handelChange(vId) {
      this.vIdType = vId;
      // let obj = {};
      // obj = this.historyOrdertime.find((item) => {
      //   return item.value === vId;
      // });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange1(vId) {
      console.log(vId);
      // let obj = {};
      // obj = this.historyOrdertimes.find((item) => {
      //   return item.value === vId;
      // });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange2(vId) {
      console.log(vId);
      // let obj = {};
      // obj = this.historyOrdertimes.find((item) => {
      //   return item.value === vId;
      // });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange3(vId) {
      console.log(vId);
      // let obj = {};
      // obj = this.historyOrdertimes.find((item) => {
      //   return item.value === vId;
      // });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange4(vId) {
      console.log(vId);
      // let obj = {};
      // obj = this.historyOrdertime1.find((item) => {
      //   return item.value === vId;
      // });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    //回电的紧急程度
    handelChangeHistory(vId) {
      let obj = {};
      obj = this.historyOrderputong.find((item) => {
        return item.value === vId;
      });
      //  console.log(obj.label);
      this.priority = obj.value;
    },
    handelChangeHistory1(vId) {
      let obj = {};
      obj = this.historyOrderputong1.find((item) => {
        return item.value === vId;
      });
      //  console.log(obj.label);
      this.priority = obj.value;
    },
    //创建工单右侧的下拉
    changexiaLa(vId) {
      console.log("==右侧下拉", vId);
      this.type = vId;
      this.gongDan();
      //清空选中的选项
      this.valueType = false;
      this.textarea = "";
      this.checkList = [];
      this.checkList1 = [];
      this.checkList2 = [];
      this.checkList3 = [];
      this.checkList4 = [];
      this.checkList5 = [];
      this.checkList6 = [];
      this.checkList7 = [];
      this.checkList8 = [];
      this.handleTime = "";
      this.valueOrdertime = "";
      this.valueOrderputong = "";
      this.valueOrdertime1 = "";
      this.valueOrderputong1 = "";
      this.valueTypes = false;
      this.callback2 = 0;
      // this.valueOrder = "";
      this.callback1 = 0;
      if (vId == "1") {
        this.isXian = true;
        this.type = "1";
        this.isXian1 = false;
        this.isXian2 = false;
        this.isXian3 = false;
        this.isXian4 = false;
        // //清空选中的选项
        // this.valueType = false;
        // this.textarea = "";
        // // this.type = "";
        // this.checkList = [];
        // this.handleTime = "";
        // this.valueOrdertime = "";
        // this.valueOrderputong = "";
        // // this.valueOrder = "";
        // this.callback1 = 0;
      } else if (vId == "2") {
        this.type = "2";
        this.isXian = false;
        this.isXian2 = false;
        this.isXian3 = false;
        this.isXian4 = false;
        this.isXian1 = true;
        // //清空选中的选项
        // this.textarea = "";
        // // this.type = "";
        // this.checkList1 = [];
        // this.checkList2 = [];
        // this.handleTime = "";
        // this.handleTime = "";
        // // this.valueOrder = "";
      } else if (vId == "3") {
        this.type = "3";
        this.isXian1 = false;
        this.isXian = false;
        this.isXian3 = false;
        this.isXian4 = false;
        this.isXian2 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList3 = [];
        // this.checkList4 = [];
        // this.handleTime = "";
        // this.handleTime = "";
        // // this.valueOrder = "";
      } else if (vId == "4") {
        this.type = "4";
        this.isXian = false;
        this.isXian1 = false;
        this.isXian2 = false;
        this.isXian4 = false;
        this.isXian3 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList5 = [];
        // this.checkList6 = [];
        // this.handleTime = "";
        // // this.valueOrder = "";
      } else if (vId == "5") {
        this.type = "5";
        this.isXian = false;
        this.isXian1 = false;
        this.isXian2 = false;
        this.isXian3 = false;
        this.isXian4 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList7 = [];
        // this.checkList8 = [];
        // this.handleTime = "";
        // this.valueOrdertime1 = "";
        // this.valueOrderputong1 = "";
        //  this.callback2 = 0;
        // // this.valueOrder = "";
        // this.valueTypes = false;
      }
    },
    // //来电时的记时
    // testTimer() {
    //   this.timer1 = setInterval(() => {
    //     this.timeFunction();
    //   }, 1000);
    // },
    // timeFunction() {
    //   // 开始执行倒计时
    //   this.timeObj = {
    //     // 时间对象
    //     seconds: Math.floor(this.currentTime % 60),
    //     minutes: Math.floor(this.currentTime / 60) % 60,
    //     hours: Math.floor(this.currentTime / 60 / 60) % 24,
    //   };
    //   // 计算出时分秒
    //   this.myHours =
    //     this.timeObj.hours < 10 ? "0" + this.timeObj.hours : this.timeObj.hours;
    //   this.myMinutes =
    //     this.timeObj.minutes < 10
    //       ? "0" + this.timeObj.minutes
    //       : this.timeObj.minutes;
    //   this.mySeconds =
    //     this.timeObj.seconds < 10
    //       ? "0" + this.timeObj.seconds
    //       : this.timeObj.seconds;
    //   this.currentTime++;
    // },
    //通话时的记时
    testTimer1() {
      this.timer2 = setInterval(() => {
        this.timeFunction1();
      }, 1000);
    },
    timeFunction1() {
      // 开始执行倒计时
      this.timeObj1 = {
        // 时间对象
        seconds: Math.floor(this.currentTime1 % 60),
        minutes: Math.floor(this.currentTime1 / 60) % 60,
        hours: Math.floor(this.currentTime1 / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours1 =
        this.timeObj1.hours < 10
          ? "0" + this.timeObj1.hours
          : this.timeObj1.hours;
      this.myMinutes1 =
        this.timeObj1.minutes < 10
          ? "0" + this.timeObj1.minutes
          : this.timeObj1.minutes;
      this.mySeconds1 =
        this.timeObj1.seconds < 10
          ? "0" + this.timeObj1.seconds
          : this.timeObj1.seconds;
      this.currentTime1++;
    },
    //通话时的记时
    testTimer2() {
      this.timer4 = setInterval(() => {
        this.timeFunction2();
      }, 1000);
    },
    timeFunction2() {
      // 开始执行倒计时
      this.timeObj2 = {
        // 时间对象
        seconds: Math.floor(this.currentTime2 % 60),
        minutes: Math.floor(this.currentTime2 / 60) % 60,
        hours: Math.floor(this.currentTime2 / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours2 =
        this.timeObj2.hours < 10
          ? "0" + this.timeObj2.hours
          : this.timeObj2.hours;
      this.myMinutes2 =
        this.timeObj2.minutes < 10
          ? "0" + this.timeObj2.minutes
          : this.timeObj2.minutes;
      this.mySeconds2 =
        this.timeObj2.seconds < 10
          ? "0" + this.timeObj2.seconds
          : this.timeObj2.seconds;
      this.currentTime2++;
    },
    //是否需要回电
    callChange(val) {
      console.log("选中：", val);
      this.historyOrdertime.forEach((item) => {
        if (item.value === "6") {
          return (this.valueOrdertime = item.value);
        }
      });
      this.historyOrderputong.forEach((item) => {
        if (item.value === "3") {
          return (this.valueOrderputong = item.value);
        }
      });
      this.callback1 = val;
      if (this.callback1 == "0") {
        this.valueType = false;
      } else if (this.callback1 == "1") {
        this.valueType = true;
      }
    },
    callChanges(val) {
      console.log("输出-----------:", val);
      this.callback2 = val;
      this.historyOrdertime1.forEach((item) => {
        if (item.value === "6") {
          return (this.valueOrdertime1 = item.value);
        }
      });
      this.historyOrderputong1.forEach((item) => {
        if (item.value === "3") {
          return (this.valueOrderputong1 = item.value);
        }
      });
      if (this.callback2 == "0") {
        this.valueTypes = false;
      } else if (this.callback2 == "1") {
        this.valueTypes = true;
      }
    },
    tableRowClassName({ row }) {
      console.log(row, "row,row");
      if (row.id === this.userId) {
        return "warning-row";
      }
    },
    //关联人员弹框
    cellClick(row) {
      // console.log('===========',row);
      this.userId = row.id;
      if (row.id === this.userId) {
        this.usersName = row.name;
        this.dialogVisible = false;
        return "warning-row";
      }
    },
    //改派人员弹框
    cellClick1(row) {
      //console.log('===========',row);
      this.userId = row.id;
    },
    //完成的二次确定
    quitFinish() {
      this.dialogVisibleFinish = false;
      apiShout.getUpdateWorkOrder(this.quFinish).then((res) => {
        console.log("更新工单：", res.data);
        if (res.data.code == 200) {
          //重新请求页面
          this.backlog();
          this.law();
          this.policy();
          this.care();
          this.$message({
            message: "恭喜你，更新工单成功",
            type: "success",
          });
          this.historyOrderUser();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //全部待办的完成  政策解读 关爱慰问的完成
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleFinish = true;
      let obj = {
        centerType: row.centerType,
        createId: row.createId,
        customerId: row.customerId,
        describe: row.describe,
        type: row.type,
        userId: row.userId,
        workNumber: row.workNumber,
        priority: row.priority,
        status: "1",
        id: row.id,
      };
      this.quFinish = { ...obj };
      // apiShout.getUpdateWorkOrder(obj).then((res) => {
      //   console.log("更新工单：", res.data);
      //   if (res.data.code == 200) {
      //     //重新请求页面
      //     this.backlog();
      //     this.$message({
      //       message: "恭喜你，更新工单成功",
      //       type: "success",
      //     });
      //     this.historyOrderUser();
      //   }
      // });
      // //删除该行
      // this.tableData2.splice(index,1);
    },
    //法律帮助的完成
    lawFinsh(index, row) {
      //console.log(index, row);
      this.dialogVisibleFinish = true;
      let obj = {
        centerType: row.centerType,
        createId: row.createId,
        customerId: row.customerId,
        describe: row.describe,
        type: row.type,
        userId: row.userId,
        workNumber: row.workNumber,
        priority: row.priority,
        status: "1",
        id: row.id,
      };
      this.quFinish = { ...obj };
      // apiShout.getUpdateWorkOrder(obj).then((res) => {
      //   console.log("更新工单：", res.data);
      //   if (res.data.code == 200) {
      //     //重新请求页面
      //     this.backlog();
      //     this.law();
      //     this.$message({
      //       message: "恭喜你，更新工单成功",
      //       type: "success",
      //     });
      //     this.historyOrderUser();
      //   }
      // });
    },
    //政策解读的完成
    policyFinsh(index, row) {
      //console.log(index, row);
      this.dialogVisibleFinish = true;
      let obj = {
        centerType: row.centerType,
        createId: row.createId,
        customerId: row.customerId,
        describe: row.describe,
        type: row.type,
        userId: row.userId,
        workNumber: row.workNumber,
        priority: row.priority,
        status: "1",
        id: row.id,
      };
      this.quFinish = { ...obj };
      // apiShout.getUpdateWorkOrder(obj).then((res) => {
      //   //console.log("更新工单：", res.data);
      //   if (res.data.code == 200) {
      //     //重新请求页面
      //     this.backlog();
      //     this.policy();
      //     this.$message({
      //       message: "恭喜你，更新工单成功",
      //       type: "success",
      //     });
      //     this.historyOrderUser();
      //   }
      // });
    },
    //关爱慰问的完成
    careFinsh(index, row) {
      //console.log(index, row);
      this.dialogVisibleFinish = true;
      let obj = {
        centerType: row.centerType,
        createId: row.createId,
        customerId: row.customerId,
        describe: row.describe,
        type: row.type,
        userId: row.userId,
        workNumber: row.workNumber,
        priority: row.priority,
        status: "1",
        id: row.id,
      };
      this.quFinish = { ...obj };
      // apiShout.getUpdateWorkOrder(obj).then((res) => {
      //   //console.log("更新工单：", res.data);
      //   if (res.data.code == 200) {
      //     //重新请求页面
      //     this.backlog();
      //     this.care();
      //     this.$message({
      //       message: "恭喜你，更新工单成功",
      //       type: "success",
      //     });
      //     this.historyOrderUser();
      //   }
      // });
    },
    //点击树事件
    handleNodeClick(obj) {
      this.dataTitle = obj.title;
      this.dataLists = obj.text;
      console.log("---", obj);
    },
    handleClick1(tab, event) {
      console.log(tab, event);
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.label == "通话记录") {
        console.log("输出");
      } else if (tab.label == "法律帮助") {
        this.law();
      } else if (tab.label == "政策解读") {
        this.policy();
      } else if (tab.label == "关爱慰问") {
        this.care();
      } else if (tab.label == "全部待办") {
        this.backlog();
      }
      this.dateTime();
      const audioElement = this.audioElement;
      if (audioElement && audioElement.length > 0) {
        for (let i = 0; i < audioElement.length; i++) {
          this.audioElement[i].pause();
        }
      }
      // if (tab.label == "待我办理") {
      //   const audioElement = this.audioElement;
      //   if (audioElement && audioElement.length > 0) {
      //     for (let i = 0; i < audioElement.length; i++) {
      //       this.audioElement[i].pause();
      //     }
      //   }
      // }else if(tab.label == "常用知识"){
      //   const audioElement = this.audioElement;
      //   if (audioElement && audioElement.length > 0) {
      //     for (let i = 0; i < audioElement.length; i++) {
      //       this.audioElement[i].pause();
      //     }
      //   }
      // }else if(tab.label == "全部待办"){
      //   const audioElement = this.audioElement;
      //   if (audioElement && audioElement.length > 0) {
      //     for (let i = 0; i < audioElement.length; i++) {
      //       this.audioElement[i].pause();
      //     }
      //   }
      // }
    },

    // 将秒转为00:00:00格式
    format(seconds) {
      let hour =
        Math.floor(seconds / 3600) >= 10
          ? Math.floor(seconds / 3600)
          : "0" + Math.floor(seconds / 3600);
      seconds -= 3600 * hour;
      let min =
        Math.floor(seconds / 60) >= 10
          ? Math.floor(seconds / 60)
          : "0" + Math.floor(seconds / 60);
      seconds -= 60 * min;
      let sec = seconds >= 10 ? seconds : "0" + seconds;
      return hour + ":" + min + ":" + sec;
    },
    //查询全部待办工单
    backlog() {
      apiShout.getBreatheAgents().then((res) => {
        console.log("全部待办：", res.data);
        if (res.data.code === 200) {
          //对于日期的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].createDate == null) {
              res.data.result.data[i].createDate = "";
            } else {
              let a = res.data.result.data[i].createDate;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              let h = dd.getHours();
              let minute = dd.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = dd.getSeconds();
              second = second < 10 ? "0" + second : second;
              // res.data.result.data[i].createDate =
              //   y + "-" + m + "-" + d;
              res.data.result.data[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          //对于剩余时间的处理
          this.remaining = res.data.result.data;
          this.remaining.forEach((element) => {
            // alert(element.remainingTime)
            if (element.remainingTime != null) {
              element.remainingTime = element.remainingTime + "小时";
            } else {
              element.remainingTime = "已到期";
            }
          });
          // for (let j = 0; j < res.data.result.data.length; j++) {
          //   res.data.result.data[j].remainingTime =
          //     res.data.result.data[j].remainingTime + "小时";
          // }
          this.tableData2 = res.data.result.data;
          this.tableData2.forEach((item) => {
            if (item.handleTime == "0") {
              item.handleTime = "不限时间";
            } else if (item.handleTime == null || item.handleTime == "") {
              item.handleTime = "不限时间";
            } else if (item.handleTime != null || item.handleTime != "") {
              this.tableData2.handleTime = item.handleTime;
            }
          });
          this.Allcount = res.data.result.data.length;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查询法律帮助工单
    law() {
      apiShout
        .getBreatheAgents({
          type: "2",
          userId: this.userdd,
          centerType: this.centerType,
        })
        .then((res) => {
          console.log("法律帮助工单：", res.data);
          if (res.data.code == 200) {
            //对于剩余时间的处理
            this.remaining = res.data.result.data;
            this.remaining.forEach((element) => {
              // alert(element.remainingTime)
              if (element.remainingTime != null) {
                element.remainingTime = element.remainingTime + "小时";
              } else {
                element.remainingTime = "已到期";
              }
            });
            // for (let j = 0; j < res.data.result.data.length; j++) {
            //   res.data.result.data[j].remainingTime =
            //     res.data.result.data[j].remainingTime + "小时";
            // }
            this.tableDataLaw = res.data.result.data;
            this.tableDataLaw.forEach((item) => {
              if (item.handleTime == "0") {
                item.handleTime = "不限时间";
              } else if (item.handleTime == null || item.handleTime == "") {
                item.handleTime = "不限时间";
              } else if (item.handleTime != null || item.handleTime != "") {
                this.tableDataLaw.handleTime = item.handleTime;
              }
            });
            this.legalAid = res.data.result.data.length;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //查询关爱慰问工单
    care() {
      apiShout
        .getBreatheAgents({
          type: "4",
          userId: this.userdd,
          centerType: this.centerType,
        })
        .then((res) => {
          console.log("关爱慰问工单：", res.data);
          if (res.data.code == 200) {
            //对于剩余时间的处理
            this.remaining = res.data.result.data;
            this.remaining.forEach((element) => {
              // alert(element.remainingTime)
              if (element.remainingTime != null) {
                element.remainingTime = element.remainingTime + "小时";
              } else {
                element.remainingTime = "已到期";
              }
            });
            // for (let j = 0; j < res.data.result.data.length; j++) {
            //   res.data.result.data[j].remainingTime =
            //     res.data.result.data[j].remainingTime + "小时";
            // }
            this.tableDataCare = res.data.result.data;
            this.tableDataCare.forEach((item) => {
              if (item.handleTime == "0") {
                item.handleTime = "不限时间";
              } else if (item.handleTime == null || item.handleTime == "") {
                item.handleTime = "不限时间";
              } else if (item.handleTime != null || item.handleTime != "") {
                this.tableDataCare.handleTime = item.handleTime;
              }
            });
            this.careSympathy = res.data.result.data.length;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //查询政策解读
    policy() {
      apiShout
        .getBreatheAgents({
          type: "3",
          userId: this.userdd,
          centerType: this.centerType,
        })
        .then((res) => {
          console.log("政策解读工单：", res.data);
          if (res.data.code == 200) {
            //对于剩余时间的处理
            this.remaining = res.data.result.data;
            this.remaining.forEach((element) => {
              // alert(element.remainingTime)
              if (element.remainingTime != null) {
                element.remainingTime = element.remainingTime + "小时";
              } else {
                element.remainingTime = "已到期";
              }
            });
            // for (let j = 0; j < res.data.result.data.length; j++) {
            //   res.data.result.data[j].remainingTime =
            //     res.data.result.data[j].remainingTime + "小时";
            // }
            this.tableDataPolicy = res.data.result.data;
            this.tableDataPolicy.forEach((item) => {
              if (item.handleTime == "0") {
                item.handleTime = "不限时间";
              } else if (item.handleTime == null || item.handleTime == "") {
                item.handleTime = "不限时间";
              } else if (item.handleTime != null || item.handleTime != "") {
                this.tableDataPolicy.handleTime = item.handleTime;
              }
            });
            this.policyElucidation = res.data.result.data.length;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //查询用户历史工单
    historyOrderUser() {
      apiShout.getUserHistory({ customerId: this.customerIds }).then((dd) => {
        console.log("用户历史工单:", dd.data);
        if (dd.data.code === 200) {
          //对于日期的处理
          for (let i = 0; i < dd.data.result.length; i++) {
            if (dd.data.result[i].createDate == null) {
              dd.data.result[i].createDate = "";
            } else {
              let a = dd.data.result[i].createDate;
              let mm = dd.data.result[i].typeDetail.slice(1, -1);
              let ds = new Date(a);
              let y = ds.getFullYear();
              let m = ds.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = ds.getDate();
              d = d < 10 ? "0" + d : d;
              let h = ds.getHours();
              let minute = ds.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = ds.getSeconds();
              second = second < 10 ? "0" + second : second;
              dd.data.result[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
              dd.data.result[i].typeDetail = mm;
            }
            this.historyList = dd.data.result;
          }
          this.historyList = dd.data.result;
        } else {
          this.$message.error(dd.data.msg);
        }
      });
    },
    handleCloseFinish() {
      this.dialogVisibleFinish = false;
    },
    //请求挂断的状态的接口
    endReason() {
      this.isListion = true;
      this.isListioning = false;
      //隐藏接听按钮
      this.isAnswer = false;
      this.textPhone = null;
      this.PersonstatusIng = false;
      clearInterval(this.timer5);
      //客服状态的计时
      clearInterval(this.timer4);
      this.currentTime2 = 0;
      this.testTimer2();
      // clearInterval(this.timer5);
      // if (this.isListion == true) {
      //   this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
      // }
      //暂停接通时的计时器
      // clearInterval(this.timer2);
      // this.currentTime1 = 0;
      //清除本地
      localStorage.removeItem("phone");
      localStorage.removeItem("phoneNumber");
    },
    offenKnow() {
      //获取常用知识
      apiShout.getKnowledge().then((res) => {
        //console.log('常用知识：',res.data);
        if (res.data.code === 200) {
          this.dataList = res.data.result;
          this.dataLists = res.data.result;
          if (this.dataLists.length > 0) {
            for (var i = 0; i < this.dataLists.length; i++) {
              for (var j = 0; j < this.dataLists[i].childs.length; j++) {
                this.dataTitle = this.dataLists[i].childs[0].title;
                this.dataLists = this.dataLists[i].childs[0].text;
              }
            }
          } else {
            this.dataLists = "";
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  beforeDestroy() {
    this.close();
    localStorage.clear("cno");
    // clearInterval(this.timer5);
    clearInterval(this.timer4);
    clearInterval(this.timer2);
    clearInterval(this.timer1);
  },
};
</script>

<style lang='less' scoped>
.top-rig {
  float: right;
}
.top-right {
  float: left;
  .r1 {
    transform: rotate(-5deg);
  }
  .r2 {
    transform: rotate(3deg);
  }
}
.top-time {
  float: left;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.top-jie {
  float: left;
}
//标记
/deep/.el-table .warning-row {
  background: #f09c83 !important;
}
.badge {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 210px;
  top: 80px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge1 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 323px;
  top: 80px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge2 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 435px;
  top: 80px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge3 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 660px;
  top: 80px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badges {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 210px;
  top: 96px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badges1 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 323px;
  top: 96px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badges2 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 435px;
  top: 96px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badges3 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 660px;
  top: 96px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
// 最上边
.center-top {
  position: relative;
  margin: 10px 30px;
  height: 80px;
  line-height: 80px;
}
.top-name {
  font-size: 30px;
  color: #92aca7;
  margin-left: 20px;
  float: left;
}
.lisitions {
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: #76b935;
  color: #fff;
  border-radius: 20px;
  float: left;
  margin: 28px 10px 10px 50px;
}
.top-tome {
  float: left;
}
.top-tu {
  display: inline-block;
  margin-left: 50px;
}
.top-tu-one {
  float: left;
  // margin-top: 15px;
}
.top-tu-one:nth-child(2) {
  margin-left: 10px;
}
.top-tu-one:nth-child(3) {
  margin-left: 10px;
}
.top-tu-one:nth-child(4) {
  margin-left: 10px;
}
.top-tu-one:nth-child(5) {
  margin-left: 10px;
}
.top-tu-one:last-child {
  margin-left: 13px;
}
.top-tu:first-child {
  margin-left: 50px;
}
//其他
.baocun {
  position: absolute;
  left: 500px;
  bottom: 10px;
}
.home .el-aside {
  background: #fff;
}
.tonghua {
  text-align: center;
  color: rgb(192, 189, 189);
}
/deep/ .el-table th.el-table__cell > .cell {
  background-color: #92aca7;
  color: #fff;
  font-size: 17px;
  padding: 10px;
}
/deep/ .el-table tr:nth-child(odd) {
  background-color: #e5f4f1;
}
/deep/ .el-table tr:nth-child(even) {
  background-color: #fff;
}
// .bigRight-tab{
//   float:right;
// }
.tabs {
  display: flex;
  //margin: 20px 20px;
  margin-left: 20px;
  // width: 52%;
  // border: 1px solid #eee;
  font-size: 16px;
  /deep/ .el-tabs__item {
    color: #d78673;
    font-size: 18px;
  }
  /deep/ .el-tabs__item.is-active {
    color: #fff;
    background-color: #d78673;
  }
  // /deep/ .el-tabs--left .el-tabs__item.is-left {
  //   color: #92aca7;
  // }
  /deep/ .el-tree-node__label.aside-tree {
    color: #fff;
    background-color: #d78673;
  }
  /deep/ .el-input__inner {
    height: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
    //width: 230px;
  }
  /deep/ .el-tree-node__label {
    color: #92aca7;
    font-size: 18px;
  }
  .search {
    display: flex;
    width: 300px;
    font-size: 16px;
    .imgs {
      width: 53px;
      height: 31px;
      margin-left: 10px;
    }
  }
  .left {
    display: flex;
    margin: 10px 0;
    font-size: 16px;
    color: #a8a8a8;
    .el-tabs--left .el-tabs__item.is-left {
      text-align: center;
    }
  }
  .right {
    line-height: 23px;
    padding: 15px;
    white-space: pre-wrap;
  }
  .tabs-left {
    flex: 1;
  }
  //右边的
  .bigRigth {
    flex: 1;
    position: relative;
    height: 1000px;
  }
  // /deep/ .el-tabs__nav-scroll{
  //   float:right;
  // }
  [class*="el-col-"] {
    height: 40px;
  }
  .inforTop {
    height: 320px;
    font-size: 19px;
    // background: lemonchiffon;
  }
  .inforbirth {
    position: absolute;
    left: 22px;
    top: 166px;
  }
  .dengji {
    position: absolute;
    left: 22px;
    top: 230px;
  }
  .inforbirths {
    position: absolute;
    left: 40%;
    top: 182px;
  }
  .inforInput {
    position: absolute;
    left: 110px;
    top: 260px;
    width: 255px;
    border-color: #eee;
  }
  .shiji {
    position: absolute;
    left: 20px;
    top: 300px;
  }
  .shijibox {
    //border: 1px solid #ebeaea;
    font-size: 16px;
    color: rgb(185, 179, 179);
  }
  .jihuo {
    position: absolute;
    left: 400px;
    top: 220px;
  }
  .daoqi {
    position: absolute;
    left: 400px;
    top: 260px;
  }

  .information {
    // background: rgb(238, 236, 236);
    border: 1px solid #f0eeee;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
    width: 600px;
    float: left;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .baseInformation {
      color: #92aca7;
      font-size: 19px;
      font-weight: bolder;
    }
  }
  /deep/ .el-form--inline .el-form-item__label {
    font-size: 17px;
    font-weight: bolder;
    color: #a8a8a8;
  }
  .big-center {
    display: flex;
  }
  .left,
  .leftcenter,
  .rightcenter {
    background: #ffffff;
    border-radius: 15px;
    // margin: 0 10px;
    // float: left;
    flex: 1;
    // 大字
    .span1 {
      display: block;
      font-size: 19px;
      font-weight: bolder;
      color: #92aca7;
      text-align: left;
      // line-height: 44px;
    }
    table {
      width: 100%;
      margin-top: 20px;
      tr {
        // margin-bottom: 17px;
        display: flex;
        td:first-child {
          color: #a8a8a8;
          font-weight: bolder;
          font-size: 17px;
          width: 80px;
        }
        td:nth-child(2) {
          font-size: 16px;
          color: #a8a8a8;
          padding-right: 15px;
          flex: 1;
        }
        td {
          .tdspan {
            line-height: 35px;
          }
          .el-select {
            width: 100%;
            .el-input__inner {
              border: 1px solid #ffffff;
              color: #c8c8c8;
              font-size: 16px;
            }
          }
        }
        .infor-img {
          margin-left: 110px;
        }
      }
    }
  }
  .history {
    width: 95%;
    border: 1px solid #f0eeee;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .historydan {
    color: #92aca7;
    font-size: 19px;
    font-weight: bolder;
  }
  .history-ul {
    height: 350px;
    //background-color: #d78673;
    overflow: auto;
  }
  .suggest {
    width: 80px;
    height: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #92aca7;
    color: #fff;
    font-size: 15px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    margin: 10px;
  }
  .suggest1 {
    // width:40%;
    height: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    // background-color: #e5f4f1;
    color: #a8a8a8;
    font-size: 15px;
    text-align: left;
    line-height: 20px;
    margin: 10px;
  }
  .person {
    color: #92aca7;
    font-size: 16px;
    margin: 10px 5px 10px 20px;
  }
  .person-id {
    color: #92aca7;
    font-size: 16px;
    margin: 10px 5px 10px 20px;
  }
  .times {
    color: rgb(192, 188, 188);
    font-size: 14px;
  }
  .content {
    color: rgb(192, 188, 188);
    font-size: 14px;
    margin: 0px 0px 10px 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d6d4d4;
  }
  .historydan1 {
    color: #92aca7;
    font-size: 19px;
    font-weight: bolder;
    display: inline-block;
  }
  .create-order {
    width: 95%;
    border: 1px solid #f0eeee;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .infor-content {
    display: flex;
  }
  .inforwrite {
    width: 300px;
    height: 200px;
    border: 1px solid #d6d4d4;
  }

  .fenglei {
    color: #92aca7;
    font-size: 15px;
    margin-left: 20px;
  }
  .radio-dan {
    margin: 10px 10px 10px 20px;
  }
  .his-right {
    margin: 0px 10px 10px 10px;
  }
  .infor-bottom {
    margin-top: 10px;
  }
  .benji-phone {
    margin-left: 10px;
    color: rgb(199, 196, 196);
  }
  .buttons {
    width: 60px;
    height: 30px;
    line-height: 20px;
    text-align: center;
    padding: 5px;
    border-radius: 25px;
    background-color: #d78673;
    color: #ffffff;
    font-size: 14px;
    display: inline-block;
    margin: 10px;
  }
}
/deep/ .bigRigth .el-tabs__nav-scroll {
  float: right;
}
/deep/ .el-tabs__content {
  border: 1px solid #eee;
}
.top-zhuangtai {
  float: left;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.my-zhuangtai {
  margin-right: 20px;
}
.top-tonghua {
  position: absolute;
  top: 30px;
  right: 30px;
}
.guan {
  img {
    width: 20px;
  }
  span {
    font-size: 14px;
    margin-left: 10px;
  }
}
.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0em;
  color: #c2bebe;
}
.time-card {
  margin: 0 5px;
  text-align: center;
}
.time-card-count {
  font-size: 14px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
}
.time-card-label {
  font-size: 0.625em;
  text-transform: uppercase;
  opacity: 0.7;
}
.top-zhuangtai1 {
  float: right;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
audio {
  width: 100%;
  height: 40px;
  outline: none;
  filter: none;
}

audio::-webkit-media-controls-enclosure {
  background: none;
  border-radius: 4px;
}
.media-controls-container,
.media-controls-container * {
  background: none;
  border-radius: 4px;
}
audio::-webkit-media-controls-play-button {
  height: 22px;
  width: 22px;
  min-width: 22px;
  border-radius: 50%;
  flex-basis: 22px;
}
audio::-webkit-media-controls-volume-control-container {
  display: none;
}

audio::-webkit-media-controls-current-time-display {
  order: 1;
  color: #000;
  text-shadow: unset;
}

audio::-webkit-media-controls-time-remaining-display {
  order: 2;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: unset;
}
/deep/.el-dialog__body {
  padding: 0 0 14px 0;
  margin: 10px;
}
</style>